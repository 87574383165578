/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListPermissionsResult } from '../models/list-permissions-result';
import { SetDefaultsCommand } from '../models/set-defaults-command';
import { TogglePermissionCommand } from '../models/toggle-permission-command';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation permissionsGet
   */
  static readonly PermissionsGetPath = '/api/Permissions';

  /**
   * Jogosultságok listázása felhasználónként.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsGet$Plain$Response(params?: {
    SearchStr?: string;
    UserId?: number;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListPermissionsResult>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsService.PermissionsGetPath, 'get');
    if (params) {
      rb.query('SearchStr', params.SearchStr, {});
      rb.query('UserId', params.UserId, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPermissionsResult>;
      })
    );
  }

  /**
   * Jogosultságok listázása felhasználónként.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsGet$Plain(params?: {
    SearchStr?: string;
    UserId?: number;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListPermissionsResult> {

    return this.permissionsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListPermissionsResult>) => r.body as ListPermissionsResult)
    );
  }

  /**
   * Jogosultságok listázása felhasználónként.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsGet$Response(params?: {
    SearchStr?: string;
    UserId?: number;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListPermissionsResult>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsService.PermissionsGetPath, 'get');
    if (params) {
      rb.query('SearchStr', params.SearchStr, {});
      rb.query('UserId', params.UserId, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPermissionsResult>;
      })
    );
  }

  /**
   * Jogosultságok listázása felhasználónként.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsGet(params?: {
    SearchStr?: string;
    UserId?: number;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListPermissionsResult> {

    return this.permissionsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListPermissionsResult>) => r.body as ListPermissionsResult)
    );
  }

  /**
   * Path part for operation toggle
   */
  static readonly TogglePath = '/api/Permissions/Toggle';

  /**
   * Jogsultság állítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggle$Response(params?: {
    body?: TogglePermissionCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsService.TogglePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Jogsultság állítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggle(params?: {
    body?: TogglePermissionCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.toggle$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setDefaults
   */
  static readonly SetDefaultsPath = '/api/Permissions/SetDefaults';

  /**
   * Jogsultság visszaállítása alapértelmezett szintre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDefaults()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDefaults$Response(params?: {
    body?: SetDefaultsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsService.SetDefaultsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Jogsultság visszaállítása alapértelmezett szintre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDefaults$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDefaults(params?: {
    body?: SetDefaultsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.setDefaults$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
