/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExportLogEventsQuery } from '../models/export-log-events-query';
import { ListLogEventsQuery } from '../models/list-log-events-query';
import { ListLogEventsResult } from '../models/list-log-events-result';

@Injectable({
  providedIn: 'root',
})
export class LogEventService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation logEventList
   */
  static readonly LogEventListPath = '/api/LogEvent/List';

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logEventList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventList$Plain$Response(params?: {
    body?: ListLogEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListLogEventsResult>> {

    const rb = new RequestBuilder(this.rootUrl, LogEventService.LogEventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListLogEventsResult>;
      })
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logEventList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventList$Plain(params?: {
    body?: ListLogEventsQuery
  },
  context?: HttpContext

): Observable<ListLogEventsResult> {

    return this.logEventList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListLogEventsResult>) => r.body as ListLogEventsResult)
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logEventList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventList$Response(params?: {
    body?: ListLogEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListLogEventsResult>> {

    const rb = new RequestBuilder(this.rootUrl, LogEventService.LogEventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListLogEventsResult>;
      })
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logEventList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventList(params?: {
    body?: ListLogEventsQuery
  },
  context?: HttpContext

): Observable<ListLogEventsResult> {

    return this.logEventList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListLogEventsResult>) => r.body as ListLogEventsResult)
    );
  }

  /**
   * Path part for operation logEventExport
   */
  static readonly LogEventExportPath = '/api/LogEvent/Export';

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logEventExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventExport$Plain$Response(params?: {
    body?: ExportLogEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, LogEventService.LogEventExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logEventExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventExport$Plain(params?: {
    body?: ExportLogEventsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.logEventExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logEventExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventExport$Response(params?: {
    body?: ExportLogEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, LogEventService.LogEventExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logEventExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logEventExport(params?: {
    body?: ExportLogEventsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.logEventExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
