import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UINotificationService } from '../../core/providers/ui-notification.service';

// https://firstclassjs.com/display-a-loader-on-every-http-request-using-interceptor-in-angular-7/#create-interceptor

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(
        private notificationService: UINotificationService,
        private translator: TranslateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.notificationService.suppressLoader) {
            return next.handle(req);
        }

        this.notificationService.showLoader(req.method.toUpperCase() === 'GET' ?
            this.translator.instant('UI.Label.LoadingData|Adatok betöltése...') :
            this.translator.instant('UI.Label.SendingData|Adatok küldése...'));

        return next.handle(req).pipe(finalize(() => this.notificationService.hideLoader()));
    }
}
