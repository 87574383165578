/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PreferenceData } from '../models/preference-data';
import { TimeZoneData } from '../models/time-zone-data';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation preferenceTimezones
   */
  static readonly PreferenceTimezonesPath = '/api/Preference/Timezones';

  /**
   * Időzónák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferenceTimezones$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceTimezones$Plain$Response(params?: {
    SearchTerm?: string;
    DateTime?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TimeZoneData>>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferenceTimezonesPath, 'post');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateTime', params.DateTime, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeZoneData>>;
      })
    );
  }

  /**
   * Időzónák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferenceTimezones$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceTimezones$Plain(params?: {
    SearchTerm?: string;
    DateTime?: string;
  },
  context?: HttpContext

): Observable<Array<TimeZoneData>> {

    return this.preferenceTimezones$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TimeZoneData>>) => r.body as Array<TimeZoneData>)
    );
  }

  /**
   * Időzónák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferenceTimezones()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceTimezones$Response(params?: {
    SearchTerm?: string;
    DateTime?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TimeZoneData>>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferenceTimezonesPath, 'post');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateTime', params.DateTime, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeZoneData>>;
      })
    );
  }

  /**
   * Időzónák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferenceTimezones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceTimezones(params?: {
    SearchTerm?: string;
    DateTime?: string;
  },
  context?: HttpContext

): Observable<Array<TimeZoneData>> {

    return this.preferenceTimezones$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TimeZoneData>>) => r.body as Array<TimeZoneData>)
    );
  }

  /**
   * Path part for operation preferenceGet
   */
  static readonly PreferenceGetPath = '/api/Preference/Get/Get';

  /**
   * Preferenciák lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferenceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceGet$Plain$Response(params?: {
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PreferenceData>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferenceGetPath, 'get');
    if (params) {
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreferenceData>;
      })
    );
  }

  /**
   * Preferenciák lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferenceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceGet$Plain(params?: {
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<PreferenceData> {

    return this.preferenceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PreferenceData>) => r.body as PreferenceData)
    );
  }

  /**
   * Preferenciák lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferenceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceGet$Response(params?: {
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PreferenceData>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferenceGetPath, 'get');
    if (params) {
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreferenceData>;
      })
    );
  }

  /**
   * Preferenciák lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferenceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preferenceGet(params?: {
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<PreferenceData> {

    return this.preferenceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PreferenceData>) => r.body as PreferenceData)
    );
  }

  /**
   * Path part for operation preferencePost
   */
  static readonly PreferencePostPath = '/api/Preference/Post/Post';

  /**
   * Preferenciák létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferencePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  preferencePost$Plain$Response(params?: {
    body?: {
'RequestUserId'?: number;
'LanguageId'?: string;
'TimeZoneId'?: string;
'ThemeId'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PreferenceData>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreferenceData>;
      })
    );
  }

  /**
   * Preferenciák létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferencePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  preferencePost$Plain(params?: {
    body?: {
'RequestUserId'?: number;
'LanguageId'?: string;
'TimeZoneId'?: string;
'ThemeId'?: string;
}
  },
  context?: HttpContext

): Observable<PreferenceData> {

    return this.preferencePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PreferenceData>) => r.body as PreferenceData)
    );
  }

  /**
   * Preferenciák létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preferencePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  preferencePost$Response(params?: {
    body?: {
'RequestUserId'?: number;
'LanguageId'?: string;
'TimeZoneId'?: string;
'ThemeId'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PreferenceData>> {

    const rb = new RequestBuilder(this.rootUrl, PreferenceService.PreferencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreferenceData>;
      })
    );
  }

  /**
   * Preferenciák létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preferencePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  preferencePost(params?: {
    body?: {
'RequestUserId'?: number;
'LanguageId'?: string;
'TimeZoneId'?: string;
'ThemeId'?: string;
}
  },
  context?: HttpContext

): Observable<PreferenceData> {

    return this.preferencePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<PreferenceData>) => r.body as PreferenceData)
    );
  }

}
