import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function trailingSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if ((control !== null && control.value !== null && control.value !== undefined
      && (typeof control.value) !== 'object')
      && ((control.value as string).startsWith(' ') || (control.value as string).endsWith(' ')))
    {
      (control.value as string) = (control.value as string).trim();
      return { trailingSpace: { value: control.value } };
    } else {
      return null;
    }
  };
  //return (control: AbstractControl): ValidationErrors | null => {
  //  return (control !== null && control.value !== null && control.value !== undefined
  //    && (typeof control.value) !== 'object')
  //    && ((control.value as string).startsWith(' ') || (control.value as string).endsWith(' '))
  //    ? { trailingSpace: { value: control.value } }
  //    : null;
  //};
}
