/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation contactPost
   */
  static readonly ContactPostPath = '/api/Contact/Post';

  /**
   * Üzenet küldése a kapcsolatfelvételi űrlapon keresztül.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPost$Response(params?: {
    body?: {
'ContactRecipient.Email'?: string;
'ContactRecipient.Description'?: string;
'ContactRecipient.Id'?: number;
'ContactRecipient.Name'?: string;
'ContactRecipient.Active'?: boolean;
'Subject'?: string;
'Description'?: string;
'Attachments'?: Array<Blob>;
'RequestUserId'?: number;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Üzenet küldése a kapcsolatfelvételi űrlapon keresztül.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactPost(params?: {
    body?: {
'ContactRecipient.Email'?: string;
'ContactRecipient.Description'?: string;
'ContactRecipient.Id'?: number;
'ContactRecipient.Name'?: string;
'ContactRecipient.Active'?: boolean;
'Subject'?: string;
'Description'?: string;
'Attachments'?: Array<Blob>;
'RequestUserId'?: number;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.contactPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
