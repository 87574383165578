import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public getEnvironment(): Environment {
    const hostName = this.document.location.hostname;

    if (hostName === 'mira.momentin.hu') {
        return Environment.Production;
    }

    if (hostName === 'mira.terbe.net') {
        return Environment.Staging;
    }

    return Environment.Development;
  }
}

export enum Environment {
    Development = 'Development',
    Staging = 'Staging',
    Production = 'Production'
}