/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyUnitData } from '../models/company-unit-data';
import { CreateOrUpdateCompanyUnitCommand } from '../models/create-or-update-company-unit-command';
import { ReflexCompanyUnitData } from '../models/reflex-company-unit-data';
import { RemoveCompanyUnitCommand } from '../models/remove-company-unit-command';

@Injectable({
  providedIn: 'root',
})
export class CompanyUnitsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation companyUnitsGet
   */
  static readonly CompanyUnitsGetPath = '/api/CompanyUnits/Get';

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsGet$Plain$Response(params?: {
    Search?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  }): Observable<StrictHttpResponse<Array<CompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsGet$Plain(params?: {
    Search?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  }): Observable<Array<CompanyUnitData>> {

    return this.companyUnitsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyUnitData>>) => r.body as Array<CompanyUnitData>)
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsGet$Response(params?: {
    Search?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  }): Observable<StrictHttpResponse<Array<CompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsGet(params?: {
    Search?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  }): Observable<Array<CompanyUnitData>> {

    return this.companyUnitsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyUnitData>>) => r.body as Array<CompanyUnitData>)
    );
  }

  /**
   * Path part for operation companyUnitsExportReflexComanyUnits
   */
  static readonly CompanyUnitsExportReflexComanyUnitsPath = '/api/CompanyUnits/ExportReflexComanyUnits/ExportReflexComanyUnits';

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsExportReflexComanyUnits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsExportReflexComanyUnits$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ReflexCompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsExportReflexComanyUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexCompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsExportReflexComanyUnits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsExportReflexComanyUnits$Plain(params?: {
  }): Observable<Array<ReflexCompanyUnitData>> {

    return this.companyUnitsExportReflexComanyUnits$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReflexCompanyUnitData>>) => r.body as Array<ReflexCompanyUnitData>)
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsExportReflexComanyUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsExportReflexComanyUnits$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ReflexCompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsExportReflexComanyUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexCompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsExportReflexComanyUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyUnitsExportReflexComanyUnits(params?: {
  }): Observable<Array<ReflexCompanyUnitData>> {

    return this.companyUnitsExportReflexComanyUnits$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReflexCompanyUnitData>>) => r.body as Array<ReflexCompanyUnitData>)
    );
  }

  /**
   * Path part for operation companyUnitsPost
   */
  static readonly CompanyUnitsPostPath = '/api/CompanyUnits/Post/Post';

  /**
   * Cég/egységek létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  companyUnitsPost$Response(params?: {
    body?: CreateOrUpdateCompanyUnitCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Cég/egységek létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  companyUnitsPost(params?: {
    body?: CreateOrUpdateCompanyUnitCommand
  }): Observable<void> {

    return this.companyUnitsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation companyUnitsRemove
   */
  static readonly CompanyUnitsRemovePath = '/api/CompanyUnits/Remove/Remove';

  /**
   * Cég/egységek törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyUnitsRemove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  companyUnitsRemove$Response(params?: {
    body?: RemoveCompanyUnitCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyUnitsService.CompanyUnitsRemovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Cég/egységek törlése.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyUnitsRemove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  companyUnitsRemove(params?: {
    body?: RemoveCompanyUnitCommand
  }): Observable<void> {

    return this.companyUnitsRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
