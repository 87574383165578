/* tslint:disable */
export enum EventUserState {
  Applied = 1,
  TeamMember = 2,
  Reservist = 3,
  Queued = 4,
  Visitor = 5,
  Engaged = 6,
  Resigned = 7,
  Storno = 8,
  Possibility = 9,
}

