<mat-dialog-content class="contactfield-validation-wrapper" [formGroup]="validationForm">
  <mat-form-field appearance="outline" class="subject" *ngIf="validationType === emailValidationType">
    <mat-label>{{getValidationSubjectLabel()}}</mat-label>
    <input matInput type="email" name="email" formControlName="email" [readonly]="validationSent" #emailInputField (keydown.enter)="subjectEnterEvent()" />
    <button mat-icon-button matIconSuffix (click)="changeSubject()" *ngIf="validationSent === true">
      <mat-icon>edit</mat-icon>
    </button>
    <mat-error *ngIf="validationForm.controls['email'].hasError('required')">
      {{ 'UI.Label.Common.Error.RequiredField|Kötelező mező' | translate }}
    </mat-error>
    <mat-error *ngIf="validationForm.controls['email'].hasError('email')">
      {{ 'UI.Label.Common.Error.Email|Érvénytelen email cím' | translate }}
    </mat-error>
    <mat-error *ngIf="validationForm.controls['email'].hasError('trailingSpace')">
      {{ 'UI.Label.Common.Error.TrailingSpace|Kezdő és záró szóköz nem megengedett' | translate }}
    </mat-error>
    <mat-error *ngIf="validationForm.controls['email'].hasError('uniqueness')">
      {{ 'UI.Label.Common.Error.UserAlreadyExists|Duplikáció, a felhasználó már létezik az adatbázisban' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="subject" *ngIf="validationType === smsValidationType">
    <mat-label>{{getValidationSubjectLabel()}}</mat-label>
    <input matInput type="tel" name="phonenumber" formControlName="phonenumber" mask=" 00 000 0000" prefix="36" [readonly]="validationSent" #phoneNumberInputField (keydown.enter)="subjectEnterEvent()" />
    <button mat-icon-button matIconSuffix (click)="changeSubject()" *ngIf="validationSent === true">
      <mat-icon>edit</mat-icon>
    </button>
    <mat-error *ngIf="validationForm.controls['phonenumber'].hasError('mask')">
      {{ 'UI.Label.Common.Error.Mask.PhoneNumber|9 számjegy' | translate }}
    </mat-error>
    <mat-error *ngIf="validationForm.controls['phonenumber'].hasError('required')">
      {{ 'UI.Label.Common.Error.RequiredField|Kötelező mező' | translate }}
    </mat-error>
  </mat-form-field>
  <div class="instruction" *ngIf="validationSent === true && validationForm.controls['confirmed'].value === false && validationError() === false">
    <div class="mt-5">{{getInstructionLabel()}}</div>
  </div>
  <div class="information" [class.validation]="validationSent === true">
    <span *ngIf="validationError()">{{ 'UI.Label.Common.Error.ValidationError|Helytelen hitelesítő kód' | translate }}</span>
    <button mat-raised-button class="validate-btn"
            *ngIf="validationSent === false || validationError()"
            [class.enabled]="validateButtonDisabled() === false"
            (click)="validate()"
            [disabled]="validateButtonDisabled()">
      {{getValidationButtonText()}}
    </button>
    <div class="code-container" *ngIf="validationSent === true && validationForm.controls['confirmed'].value === false && validationError() === false">
      <span *ngIf="validationSent === true && validationForm.controls['confirmed'].value === false" class="mt-5">{{getCodePrefix()}}</span>
      <mat-form-field appearance="outline" *ngIf="validationSent === true && validationForm.controls['confirmed'].value === false">
        <mat-label>{{'UI.Label.Validation.ValidationCodeLabel|Kód' | translate}}</mat-label>
        <input matInput type="number" name="validationCode" formControlName="validationCode" #validationCodeInput (keydown.enter)="validationCodeEnterEvent()" />
        <mat-error *ngIf="validationForm.controls['validationCode'].hasError('invalidCode')">
          {{ 'UI.Label.Common.Error.ValidationError|Érvénytelen kód.' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button matTextSuffix class="validate-ok-btn mt-5" [disabled]="validationForm.controls['validationCode'].invalid"
              [class.enabled]="validationForm.controls['validationCode'].valid"
              *ngIf="validationSent === true && validationForm.controls['confirmed'].value === false" (click)="submitValidationCode()">
        {{'UI.Label.Validation.SubmitValidationCode|OK' | translate}}
      </button>
    </div>
    <div class="validated-btn" *ngIf="validationSent === true && validationForm.controls['confirmed'].value === true">
      <mat-icon>check</mat-icon>
      {{'UI.Label.Validation.ValidatedLabel|Hitelesítve' | translate}}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="validationForm" [disabled]="!validationForm.valid">{{ 'UI.Label.Common.Ok|Ok' | translate }}</button>
</mat-dialog-actions>

