import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';
import { SessionProvider } from './session.provider';

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService {
    isNewVersionAvailable: boolean = false;
    intervalSource = interval(1 * 60 * 1000); // every 15 mins
    intervalSubscription: Subscription;

    constructor(
        private swUpdate: SwUpdate,
        private zone: NgZone,
        private session: SessionProvider) {
            this.checkForUpdate();
    }

    checkForUpdate(): void {        
        this.intervalSubscription?.unsubscribe();
        if (!this.swUpdate.isEnabled) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            this.intervalSubscription = this.intervalSource.subscribe(async () => {
                try {
                    this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
                    console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
                    if (this.isNewVersionAvailable) {
                        const clientVersion = this.session.getAppVersion();
                        this.session.versionMismatch(clientVersion);

                        // this.applyUpdate();
                    }
                } catch (error) {
                    console.error('Failed to check for updates:', error);
                }
            });
        })
    }

    applyUpdate(): void {
        this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
    }
}