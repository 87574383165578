import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MustMatchDirective } from './directives/must-match.directive';
import { JWTApiInterceptor } from './interceptors/jwtheader.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LocalizationInterceptor } from './interceptors/localization.interceptor';
import { QueryStringFixerInterceptor } from './interceptors/query-string-fixer.interceptor';
import { FilterPipe } from './pipes/filter.pipe';
import { LogPipe } from './pipes/log.pipe';
import { ListViewComponent } from './components/list-view/list-view.component';
import { FormEditorComponent } from './components/form-editor/form-editor.component';
import './extensions/array.extensions';
import './extensions/number.extensions';
import './extensions/ng-form.extensions';
import { GlobalErrorHandler } from '../core/providers/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSetPasswordComponent } from './components/user-setpassword/user-setpassword.component';
import { MatIconModule } from '@angular/material/icon';
import { ContactFieldValidationComponent } from './components/contactfield-validation/contactfield-validation.component';
import { PendingChangesGuard } from './guards/pendingchanges.guard';
import { WorkInProcessGuard } from './guards/workinprocess.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        MessageModalComponent,
        ListViewComponent,
        FormEditorComponent,
        FilterPipe,
        LogPipe,
        AutofocusDirective,
        MustMatchDirective,
        UserSetPasswordComponent,
        ContactFieldValidationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        NgxMaskDirective, 
        NgxMaskPipe
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: QueryStringFixerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LocalizationInterceptor, multi: true },
        PendingChangesGuard,
        WorkInProcessGuard
    ],
    exports: [
        MessageModalComponent,
        ListViewComponent,
        FormEditorComponent,
        FilterPipe,
        LogPipe,
        AutofocusDirective,
        MustMatchDirective,
        TranslatePipe,
        TranslateModule,
        UserSetPasswordComponent,
        ContactFieldValidationComponent
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        GlobalErrorHandler,
        { provide: ErrorHandler, useExisting: GlobalErrorHandler },
        FilterPipe,
        LogPipe,
      ]
    };
  }
}
