import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

// https://stackoverflow.com/questions/41873893/angular2-autofocus-input-element

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit {
  constructor(private el: ElementRef) {
  }

  @Input() autofocus?: boolean;

  ngOnInit() {
    if (this.autofocus !== false) {
      // Otherwise Angular throws error: Expression has changed after it was checked.
      of(0)
        .pipe(delay(400))

        // For SSR (server side rendering) this is not safe.
        // Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
        .subscribe(_ => this.el.nativeElement.focus());
    }
  }
}
