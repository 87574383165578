import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable, of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { UniquenessType } from "../../../api/models";
import { UserService } from "../../../api/services";

export interface UserIdFunc {
  (): number | null
}

export function uniquenessValidator(userService: UserService, uniquenessType: UniquenessType, userIdFunc: UserIdFunc): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const userId = userIdFunc() ?? undefined;
    return ((control !== null && control.value !== null && control.value !== undefined
      && (typeof control.value) !== 'object')
      && ((control.value as string).length > 0))
      ? userService.checkUniqueness({
        body: {
          excludedUserId: userId,
          subject: (control.value as string).trim(),
          uniquenessType: uniquenessType
        }
      }).pipe(
        debounceTime(400),
        map(result => {
          return result === true
            ? { uniqueness: { value: result } }
            : null;
        })
      )
      : of({});
  }
}
