/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExportHrStatisticsQuery } from '../models/export-hr-statistics-query';
import { ListHrStatisticsQuery } from '../models/list-hr-statistics-query';
import { ListHrStatisticsResult } from '../models/list-hr-statistics-result';

@Injectable({
  providedIn: 'root',
})
export class HrStatisticsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation hrStatisticsList
   */
  static readonly HrStatisticsListPath = '/api/HrStatistics/List';

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsList$Plain$Response(params?: {
    body?: ListHrStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListHrStatisticsResult>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListHrStatisticsResult>;
      })
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsList$Plain(params?: {
    body?: ListHrStatisticsQuery
  },
  context?: HttpContext

): Observable<ListHrStatisticsResult> {

    return this.hrStatisticsList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListHrStatisticsResult>) => r.body as ListHrStatisticsResult)
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsList$Response(params?: {
    body?: ListHrStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListHrStatisticsResult>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListHrStatisticsResult>;
      })
    );
  }

  /**
   * HR Statisztika listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsList(params?: {
    body?: ListHrStatisticsQuery
  },
  context?: HttpContext

): Observable<ListHrStatisticsResult> {

    return this.hrStatisticsList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListHrStatisticsResult>) => r.body as ListHrStatisticsResult)
    );
  }

  /**
   * Path part for operation hrStatisticsExport
   */
  static readonly HrStatisticsExportPath = '/api/HrStatistics/Export';

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsExport$Plain$Response(params?: {
    body?: ExportHrStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsExport$Plain(params?: {
    body?: ExportHrStatisticsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.hrStatisticsExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsExport$Response(params?: {
    body?: ExportHrStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hrStatisticsExport(params?: {
    body?: ExportHrStatisticsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.hrStatisticsExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation hrStatisticsCsv
   */
  static readonly HrStatisticsCsvPath = '/api/HrStatistics/Csv';

  /**
   * HR Statisztika csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hrStatisticsCsv$Plain$Response(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsCsvPath, 'get');
    if (params) {
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hrStatisticsCsv$Plain(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.hrStatisticsCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * HR Statisztika csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hrStatisticsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  hrStatisticsCsv$Response(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, HrStatisticsService.HrStatisticsCsvPath, 'get');
    if (params) {
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * HR Statisztika csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hrStatisticsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hrStatisticsCsv(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.hrStatisticsCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
