<ng-container *ngTemplateOutlet="listTemplate || defaultListTemplate; context: {$implicit: items, selectItem: selectItem, defaultEmptyListTemplate: defaultEmptyListTemplate}"></ng-container>

<ng-template #defaultListTemplate let-items let-selectItem="selectItem" let-defaultEmptyListTemplate="defaultEmptyListTemplate">
    <div class="list-group mb-4 itemlist" *ngIf="items?.length > 0; else defaultEmptyListTemplate">
        <a href="javascript:void(0)" class="list-group-item list-group-item-action" *ngFor="let item of items" (click)="selectItem(item)"
           [class.active]="item === selectedItem">
            <ng-container *ngTemplateOutlet="listItemTemplate; context: {$implicit: item}"></ng-container>
        </a>
    </div>
</ng-template>

<ng-template #defaultEmptyListTemplate>
    <div class="card card-body bg-light">
        {{ 'UI.Message.NoDataToDisplay|A szűrési feltételeknek nincs megjeleníthető eredménye!' | translate }}
    </div>
</ng-template>
