import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function containsNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const regex = /\d/;
    return (control !== null && control.value !== null && control.value !== undefined)
      && regex.test(control.value)
      ? null
      : { containsNumber: { value: control.value } };
  };
}
