import { Component, Inject } from '@angular/core';
import { IModalMessage } from '../../../core/providers/ui-notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent<T> {

  constructor(
    public modalService: MatDialog,
    public dialogRef: MatDialogRef<T>,
    public translator: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public message: IModalMessage) {
  }

  public getBsClass(value: string, index = 0) {
    if (index === 0) {
      switch (value) {
        case 'error': return 'danger';
        case 'warning': return 'warning';
        case 'info': return 'primary';
      }
    }

    return 'secondary';
  }

  getIconByMessageType(): string {
    switch (this.message.type) {
      case 'error': return 'warning';
      case 'warning': return 'warning';
      case 'info': return 'info_outlined';
      case 'none': return '';
    }
  }

  public close(result: string) {
    if (this.message.onClose) {
      this.message.onClose(result);
    }
    this.dialogRef.close(result);
  }

  public getButtonText(textIndex: number, button: string): string | null {
    if (this.message.buttonText !== null && this.message.buttonText !== undefined && this.message.buttonText.length > textIndex) {
      return this.message.buttonText[textIndex];
    } else {
      switch (button) {
        case 'cancel': return this.translator.instant('UI.Label.Cancel|Mégse');
        case 'ok': return this.translator.instant('UI.Label.OK|OK');
        case 'no': return this.translator.instant('UI.Label.No|Nem');
        case 'yes': return this.translator.instant('UI.Label.Yes|Igen');
        case 'delete': return this.translator.instant('UI.Label.Delete|Törlés');
        case 'close': return this.translator.instant('UI.Label.Close|Bezár');
        default: return '';
      }
    }
  }

  public getButtonColor(index: number, button: string): string {
    if (this.message.buttonColor !== null && this.message.buttonColor !== undefined && this.message.buttonColor.length > index) {
      return this.message.buttonColor[index];
    } else {
      switch (button) {
        case 'cancel': return '';
        case 'ok': return 'primary';
        case 'no': return 'accent';
        case 'yes': return 'primary';
        case 'delete': return 'accent';
        case 'close': return 'accent';
        default: return '';
      }
    }
  }
  public getButtonBgColor(index: number, button: string): string |null {
    if (this.message.buttonBgColor !== null && this.message.buttonBgColor !== undefined && this.message.buttonBgColor.length > index) {
      return this.message.buttonBgColor[index];
    } else {
      return this.getButtonBgColorByButton(button);
    }
  }

  getButtonBgColorByButton(button: string): string |null {
    switch (button) {
      case 'ok': return '#2196f3';
      case 'yes': return '#2196f3';
      default: return null;
    }
  }

}
