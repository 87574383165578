/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttendanceExportType } from '../models/attendance-export-type';
import { BulkAdmissionCommand } from '../models/bulk-admission-command';
import { BulkModifyReportStateCommand } from '../models/bulk-modify-report-state-command';
import { ExportAttendancesQuery } from '../models/export-attendances-query';
import { ListAttendancesQuery } from '../models/list-attendances-query';
import { ListAttendancesResult } from '../models/list-attendances-result';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation attendanceListAttendances
   */
  static readonly AttendanceListAttendancesPath = '/api/Attendance/ListAttendances';

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceListAttendances$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceListAttendances$Plain$Response(params?: {
    body?: ListAttendancesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListAttendancesResult>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceListAttendancesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListAttendancesResult>;
      })
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceListAttendances$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceListAttendances$Plain(params?: {
    body?: ListAttendancesQuery
  },
  context?: HttpContext

): Observable<ListAttendancesResult> {

    return this.attendanceListAttendances$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListAttendancesResult>) => r.body as ListAttendancesResult)
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceListAttendances()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceListAttendances$Response(params?: {
    body?: ListAttendancesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListAttendancesResult>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceListAttendancesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListAttendancesResult>;
      })
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceListAttendances$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceListAttendances(params?: {
    body?: ListAttendancesQuery
  },
  context?: HttpContext

): Observable<ListAttendancesResult> {

    return this.attendanceListAttendances$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListAttendancesResult>) => r.body as ListAttendancesResult)
    );
  }

  /**
   * Path part for operation attendanceBulkModifyReportState
   */
  static readonly AttendanceBulkModifyReportStatePath = '/api/Attendance/BulkModifyReportState';

  /**
   * Lejelentés adatok csoportos módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceBulkModifyReportState()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceBulkModifyReportState$Response(params?: {
    body?: BulkModifyReportStateCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceBulkModifyReportStatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Lejelentés adatok csoportos módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceBulkModifyReportState$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceBulkModifyReportState(params?: {
    body?: BulkModifyReportStateCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.attendanceBulkModifyReportState$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation attendanceBulkAdmission
   */
  static readonly AttendanceBulkAdmissionPath = '/api/Attendance/BulkAdmission';

  /**
   * Felhasználók tömeges beléptetése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceBulkAdmission()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceBulkAdmission$Response(params?: {
    body?: BulkAdmissionCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceBulkAdmissionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználók tömeges beléptetése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceBulkAdmission$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceBulkAdmission(params?: {
    body?: BulkAdmissionCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.attendanceBulkAdmission$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation attendanceExport
   */
  static readonly AttendanceExportPath = '/api/Attendance/Export';

  /**
   * Lejelentések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceExport$Plain$Response(params?: {
    body?: ExportAttendancesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Lejelentések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceExport$Plain(params?: {
    body?: ExportAttendancesQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.attendanceExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Lejelentések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceExport$Response(params?: {
    body?: ExportAttendancesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Lejelentések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attendanceExport(params?: {
    body?: ExportAttendancesQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.attendanceExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation attendanceCsv
   */
  static readonly AttendanceCsvPath = '/api/Attendance/Csv';

  /**
   * Lejelentések exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  attendanceCsv$Plain$Response(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    OrderBy?: string;
    Descending?: boolean;
    ExportType?: AttendanceExportType;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Descending', params.Descending, {});
      rb.query('ExportType', params.ExportType, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Lejelentések exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attendanceCsv$Plain(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    OrderBy?: string;
    Descending?: boolean;
    ExportType?: AttendanceExportType;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.attendanceCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Lejelentések exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attendanceCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  attendanceCsv$Response(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    OrderBy?: string;
    Descending?: boolean;
    ExportType?: AttendanceExportType;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, AttendanceService.AttendanceCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Descending', params.Descending, {});
      rb.query('ExportType', params.ExportType, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Lejelentések exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attendanceCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attendanceCsv(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    OrderBy?: string;
    Descending?: boolean;
    ExportType?: AttendanceExportType;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.attendanceCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
