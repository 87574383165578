/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SettingData } from '../models/setting-data';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation settingsGet
   */
  static readonly SettingsGetPath = '/api/Settings/Get/{settingName}';

  /**
   * Beállítások lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsGet$Plain$Response(params: {
    settingName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingData>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsGetPath, 'get');
    if (params) {
      rb.path('settingName', params.settingName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingData>>;
      })
    );
  }

  /**
   * Beállítások lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsGet$Plain(params: {
    settingName: string;
  },
  context?: HttpContext

): Observable<Array<SettingData>> {

    return this.settingsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingData>>) => r.body as Array<SettingData>)
    );
  }

  /**
   * Beállítások lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsGet$Response(params: {
    settingName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SettingData>>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsGetPath, 'get');
    if (params) {
      rb.path('settingName', params.settingName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingData>>;
      })
    );
  }

  /**
   * Beállítások lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsGet(params: {
    settingName: string;
  },
  context?: HttpContext

): Observable<Array<SettingData>> {

    return this.settingsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SettingData>>) => r.body as Array<SettingData>)
    );
  }

  /**
   * Path part for operation settingsPost
   */
  static readonly SettingsPostPath = '/api/Settings/Post';

  /**
   * Beállítás módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  settingsPost$Response(params?: {
    body?: SettingData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Beállítás módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  settingsPost(params?: {
    body?: SettingData
  },
  context?: HttpContext

): Observable<void> {

    return this.settingsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
