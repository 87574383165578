import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function lowercaseLettersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const regex = /[a-z]/g;
    return (control !== null && control.value !== null && control.value !== undefined)
      && regex.test(control.value)
      ? null
      : { lowercaseLetter: { value: control.value } }
  };
}
