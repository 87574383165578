import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, of } from 'rxjs';

@Injectable()
export class TranslationLoaderResolver {
  constructor(
    private translate: TranslateService // private languageService: SetLanguageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let reloadTranslation = false;

    const translated = this.translate.instant('UI.Common.Validated');

    if (translated === 'UI.Common.Validated') {
      reloadTranslation = true;
    }

    const langCode = 'hu-HU';

    if (reloadTranslation == true) {
      return new Observable((observer: Observer<any>) => {
        this.translate.getTranslation(langCode).subscribe({
          next: (x) => {
            //console.log(x)
          },
          complete: () => {
            observer.next(true);
            observer.complete();
          },
          error: (e) => {
            console.error(e);
            observer.next(true);
            observer.complete();
          },
        });
      });
    }

    return of(true);
  }
}
