/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class I18NService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLanguages
   */
  static readonly GetLanguagesPath = '/api/I18n/GetLanguages';

  /**
   * Támogatott nyelvek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLanguages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, I18NService.GetLanguagesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Támogatott nyelvek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLanguages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages$Plain(params?: {
  },
  context?: HttpContext

): Observable<any> {

    return this.getLanguages$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Támogatott nyelvek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, I18NService.GetLanguagesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Támogatott nyelvek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages(params?: {
  },
  context?: HttpContext

): Observable<any> {

    return this.getLanguages$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getTranslations
   */
  static readonly GetTranslationsPath = '/api/I18n/GetTranslations';

  /**
   * Fordítások lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTranslations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslations$Plain$Response(params?: {
    langCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, I18NService.GetTranslationsPath, 'get');
    if (params) {
      rb.query('langCode', params.langCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Fordítások lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTranslations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslations$Plain(params?: {
    langCode?: string;
  },
  context?: HttpContext

): Observable<any> {

    return this.getTranslations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Fordítások lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTranslations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslations$Response(params?: {
    langCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, I18NService.GetTranslationsPath, 'get');
    if (params) {
      rb.query('langCode', params.langCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Fordítások lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTranslations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslations(params?: {
    langCode?: string;
  },
  context?: HttpContext

): Observable<any> {

    return this.getTranslations$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
