/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangePasswordModel } from '../models/change-password-model';
import { FinishRegistrationCommand } from '../models/finish-registration-command';
import { GetNewPasswordCommand } from '../models/get-new-password-command';
import { RegistrationIdValidQuery } from '../models/registration-id-valid-query';
import { RegistrationIdValidResult } from '../models/registration-id-valid-result';
import { UserData } from '../models/user-data';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation profileGet
   */
  static readonly ProfileGetPath = '/api/Profile';

  /**
   * Saját felhasználói adatok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGet$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Saját felhasználói adatok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGet$Plain(params?: {
  },
  context?: HttpContext

): Observable<UserData> {

    return this.profileGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Saját felhasználói adatok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Saját felhasználói adatok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGet(params?: {
  },
  context?: HttpContext

): Observable<UserData> {

    return this.profileGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Path part for operation registrationIdExists
   */
  static readonly RegistrationIdExistsPath = '/api/Profile/RegistrationIdExists';

  /**
   * Regisztrációs link ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationIdExists$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registrationIdExists$Plain$Response(params?: {
    body?: RegistrationIdValidQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RegistrationIdValidResult>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.RegistrationIdExistsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationIdValidResult>;
      })
    );
  }

  /**
   * Regisztrációs link ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationIdExists$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registrationIdExists$Plain(params?: {
    body?: RegistrationIdValidQuery
  },
  context?: HttpContext

): Observable<RegistrationIdValidResult> {

    return this.registrationIdExists$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<RegistrationIdValidResult>) => r.body as RegistrationIdValidResult)
    );
  }

  /**
   * Regisztrációs link ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationIdExists()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registrationIdExists$Response(params?: {
    body?: RegistrationIdValidQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RegistrationIdValidResult>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.RegistrationIdExistsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationIdValidResult>;
      })
    );
  }

  /**
   * Regisztrációs link ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationIdExists$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registrationIdExists(params?: {
    body?: RegistrationIdValidQuery
  },
  context?: HttpContext

): Observable<RegistrationIdValidResult> {

    return this.registrationIdExists$Response(params,context).pipe(
      map((r: StrictHttpResponse<RegistrationIdValidResult>) => r.body as RegistrationIdValidResult)
    );
  }

  /**
   * Path part for operation finishRegistration
   */
  static readonly FinishRegistrationPath = '/api/Profile/FinishRegistration';

  /**
   * Regisztráció befejezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finishRegistration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  finishRegistration$Response(params?: {
    body?: FinishRegistrationCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.FinishRegistrationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Regisztráció befejezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finishRegistration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  finishRegistration(params?: {
    body?: FinishRegistrationCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.finishRegistration$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changePassword
   */
  static readonly ChangePasswordPath = '/api/Profile/ChangePassword';

  /**
   * Saját jelszó módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changePassword$Response(params?: {
    body?: ChangePasswordModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Saját jelszó módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changePassword(params?: {
    body?: ChangePasswordModel
  },
  context?: HttpContext

): Observable<void> {

    return this.changePassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getNewPassword
   */
  static readonly GetNewPasswordPath = '/api/Profile/GetNewPassword';

  /**
   * Új jelszóigénylés indítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewPassword$Response(params?: {
    body?: GetNewPasswordCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetNewPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Új jelszóigénylés indítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewPassword(params?: {
    body?: GetNewPasswordCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.getNewPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
