import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalPermissions } from '../../../api/enums/global-permissions';
import { AuthService } from '../../../api/services/auth.service';
import { ConfigService } from '../../../api/services/config.service';
import {
  GetUserInfoResult,
  LabelData,
  LoginResponseModel,
  LoginResponseUserDataModel,
  ManualData,
  PreferenceData,
  UserData,
  UserState,
} from '../../../api/models';
import { UINotificationService } from './ui-notification.service';
import { PreferenceService, UserService } from '../../../api/services';
import { PictureType } from '../../../api/enums/picture-type';
import { UserImagesService } from '../../../api/images/user-images-service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Theme } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class SessionProvider {
  authorized$: EventEmitter<boolean> = new EventEmitter();
  projectChanged$: EventEmitter<number | null> = new EventEmitter();
  configLoaded$: EventEmitter<null> = new EventEmitter();

  sideNavSlimmed$: EventEmitter<boolean> = new EventEmitter();
  sideNavShown$: EventEmitter<boolean> = new EventEmitter();

  searchTextChanged$: EventEmitter<string> = new EventEmitter();
  profilePictureChanged$: EventEmitter<null> = new EventEmitter();

  versionMismatch$: EventEmitter<string> = new EventEmitter();
  manualNotAccepted$: EventEmitter<ManualData[] | null> = new EventEmitter();

  /* a konfiguráció kulcsai */
  private readonly APPVERSION_KEY = 'AppVersion';
  private readonly MAXPAGESIZE_KEY = 'MaxPageSize';
  private readonly SHIFTLENGTH_KEY = 'ShiftLength';
  private readonly REDIRECTURL_KEY = 'RedirectUrl';

  private readonly AVAILABLE_LANGUAGES_KEY = 'AvailableLanguages';
  private readonly DEFAULT_LANGUAGE_KEY = 'DefaultLanguage';
  private readonly CURRENT_LANGUAGE_KEY = 'CurrentLanguage';

  private readonly GOOGLE_CLIENT_ID = 'GoogleClientId';
  private readonly FACEBOOK_CLIENT_ID = 'FacebookClientId';
  private readonly RECAPTCHA_SITEKEY_ID = 'RecaptchaV3Sitekey';

  private readonly BUILTIN_COMPANYNAME_KEY = 'BuiltInCompanyName';
  private readonly BUILTIN_LABELNAME_KEY = 'BuiltInLabelName';
  private readonly DEFAULT_COUNTRYNAME_KEY = 'DefaultCountryName';
  private readonly DEFAULT_NATIONALITY_KEY = 'DefaultNationality';

  private readonly PREFIX_ADVERTISEMENTLINK_KEY = 'AdvertisementLinkPrefix';
  private readonly PREFIX_REGISTRATIONLINK_KEY = 'RegistrationLinkPrefix';
  private readonly BASEURL_KEY = 'BaseUrl';

  /* local storage-ben tárolt értékek kulcsai */
  private readonly JWT_TOKEN_KEY = 'jwttoken';

  private readonly SIDENAV_SHOWN_KEY = 'SideNavShown';
  private readonly SIDENAV_SLIMMED_KEY = 'SideNavSlimmed';

  private readonly SELECTED_ROWS_KEY_PREFIX = 'SelectedRows.';
  private readonly FILTERS_KEY_PREFIX = 'Filters.';
  private readonly SELECTION_MODE_KEY_PREFIX = 'SelectionMode.';

  private readonly USERDATA_KEY = 'user';
  private readonly USERVIEW_LABELS_KEY = 'lv';
  private readonly USER_PREFERENCE_KEY = 'userpreference';

  /* az alkalmazás élettartama alatt tárolt értékek */
  private appVersion!: string;
  private maxPageSize?: number;
  private defaultLang!: string;
  private googleClientId?: string;
  private facebookClientId?: string;
  private recaptchaSiteKey?: string;
  private BuiltInCompanyName?: string;
  private BuiltInLabelName?: string;
  private DefaultCountryName?: string;
  private DefaultNationality?: string;
  private AdvertisementLinkPrefix: string;
  private RegistrationLinkPrefix: string;
  private BaseUrl: string;
  private redirectUrl: string;

  private userId?: number;
  private userProfilePicture: string | null = null;
  private userData: UserData | null = null;
  private globalPermissionList: string | null | undefined = '1';
  private shiftLength?: number = 12;
  private timeZoneOffsetMS: number = new Date().getTimezoneOffset() * 60000;
  private notAcceptedManuals: ManualData[] | null | undefined;

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    private notificationService: UINotificationService,
    private translator: TranslateService,
    private userService: UserService,
    private userImagesService: UserImagesService,
    private preferenceService: PreferenceService
  ) {}

  async reloadConfig() {
    const config = (await this.configService
      .get()
      .toPromise()) as StringKeyedDictionaryLike<string>;

    // TODO: config értékek frissítése hogyan/mikor?
    this.appVersion = config[this.APPVERSION_KEY];
    this.maxPageSize = +config[this.MAXPAGESIZE_KEY];
    if (isNaN(this.maxPageSize)) {
      this.maxPageSize = void 0;
    }
    this.shiftLength = +config[this.SHIFTLENGTH_KEY];

    // konfiguráljuk a TranslateService-t
    const langs = (config[this.AVAILABLE_LANGUAGES_KEY] || '')
      .split(',')
      .map((l) => l.trim());
    this.translator.langs = [];
    this.translator.addLangs(langs);

    this.defaultLang = config[this.DEFAULT_LANGUAGE_KEY] || 'hu-HU';

    // beállítjuk a használandó nyelvet
    let currentLang = this.getCurrentLanguage();
    if (!currentLang) {
      currentLang = this.translator.getBrowserCultureLang();
    }

    if (langs.findIndex((lang) => lang === currentLang) < 0) {
      currentLang = this.defaultLang;
    }

    this.setCurrentLanguage(currentLang);
    await this.translator.use(currentLang ?? this.defaultLang).toPromise();

    this.googleClientId = config[this.GOOGLE_CLIENT_ID];
    this.facebookClientId = config[this.FACEBOOK_CLIENT_ID];
    this.recaptchaSiteKey = config[this.RECAPTCHA_SITEKEY_ID];

    this.DefaultCountryName = config[this.DEFAULT_COUNTRYNAME_KEY];
    this.DefaultNationality = config[this.DEFAULT_NATIONALITY_KEY];
    this.BuiltInCompanyName = config[this.BUILTIN_COMPANYNAME_KEY];
    this.BuiltInLabelName = config[this.BUILTIN_LABELNAME_KEY];

    this.RegistrationLinkPrefix = config[this.PREFIX_REGISTRATIONLINK_KEY];
    this.AdvertisementLinkPrefix = config[this.PREFIX_ADVERTISEMENTLINK_KEY];
    this.BaseUrl = config[this.BASEURL_KEY];

    this.configLoaded$.emit();
  }

  async check(): Promise<boolean> {
    let result: GetUserInfoResult;
    try {
      result = await this.authService.check().toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return false;
      }

      throw error;
    }

    this.userId = result.userId;
    this.globalPermissionList = result.globalPermissionList;

    this.setToken(result.token);

    return true;
  }

  async login(
    username: string,
    password: string,
    redirectUrl?: string | null | undefined,
    recaptchaToken?: string
  ): Promise<boolean | undefined> {
    let result: LoginResponseModel;
    try {
      result = await this.authService
        .login({
          body: {
            username: username,
            password: password,
            recaptchaToken: recaptchaToken,
          },
        })
        .toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        return null;
      }

      throw error;
    }
    this.setUserData(result.user);

    this.setToken(result.token);
    if(result.uid) {
      this.updateProfilePicture(parseInt(result.uid));
    }

    this.userData = await this.getUserStateData().toPromise();
    const userPreferences = await this.preferenceService
      .preferenceGet()
      .toPromise();
    this.setUserPreferenceData(userPreferences);

    this.authorized$.emit(true);

    this.setRedirectUrl(null);

    const isRegistered =
      this.userData.state == UserState.Active ||
      this.userData.state == UserState.Admissioned ||
      this.userData.state == UserState.Registered;

    this.manualNotAccepted$.emit(result.user?.notAcceptedManuals);
    this.notAcceptedManuals = result.user?.notAcceptedManuals;
    this.saveNotAcceptedManuals(result.user?.notAcceptedManuals);

    if (!isRegistered) {
      return await this.navigateSafe((router) =>
        router.navigateByUrl(redirectUrl || '/userdata')
      );
    }

    const firstAvailableRoute = this.getFirstAvailableRoute();
    return await this.navigateSafe((router) =>
      router.navigateByUrl(redirectUrl || firstAvailableRoute)
    );
  }
  public updateProfilePicture(userId: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userImagesService.getUserImageAsInlineImage(
        {
          PictureType: PictureType.Profile,
          Thumbnail: true,
          UserId: userId,
        },
        (result) => {
          this.userProfilePicture = result;
          this.profilePictureChanged$.emit();
          resolve(result);
        }
      );
    });
  }
  public async getProfilePicture() {
    if (this.userProfilePicture === null) {
      const pic = await this.updateProfilePicture(this.userId!);
      return pic;
    } else {
      return this.userProfilePicture;
    }
  }
  getFirstAvailableRoute() {
    if (this.globalPermissionGranted(GlobalPermissions.G_Events_Menu)) {
      return '/events';
    } else if (
      this.globalPermissionGranted(GlobalPermissions.G_Presence_Menu)
    ) {
      return '/attendance';
    } else if (
      this.globalPermissionGranted(GlobalPermissions.G_Statistics_Menu)
    ) {
      return '/statistics';
    } else if (this.globalPermissionGranted(GlobalPermissions.G_Users_Menu)) {
      return '/users';
    } else if (this.globalPermissionGranted(GlobalPermissions.G_Events_Menu)) {
      return '/contact';
    }
    return '/';
  }
  async loginSocial(
    idToken: string,
    provider: string,
    redirectUrl?: string | null | undefined
  ): Promise<boolean | undefined> {
    let result: LoginResponseModel;
    try {
      result = await this.authService
        .loginSocial({
          body: {
            idToken: idToken,
            provider: provider,
          },
        })
        .toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        this.notificationService.showModalMessage({
          title: 'Hibás bejelentkezés',
          message: 'A felhasználó azonosítása sikertelen!',
          type: 'error',
          onClose: async (result: any) => {
            return false;
          },
        });
      }

      throw error;
    }
    this.setUserData(result.user);

    this.setToken(result.token);
    this.updateProfilePicture(parseInt(result.uid!));

    this.userData = await this.getUserStateData().toPromise();

    this.authorized$.emit(true);

    this.setRedirectUrl(null);

    const isRegistered =
      this.userData.state == UserState.Active ||
      this.userData.state == UserState.Admissioned ||
      this.userData.state == UserState.Registered;

    this.manualNotAccepted$.emit(result.user!.notAcceptedManuals);
    this.notAcceptedManuals = result.user!.notAcceptedManuals;
    this.saveNotAcceptedManuals(result.user!.notAcceptedManuals);

    if (!isRegistered) {
      return await this.navigateSafe((router) =>
        router.navigateByUrl(redirectUrl || '/userdata')
      );
    }

    return await this.navigateSafe((router) =>
      router.navigateByUrl(redirectUrl || '/')
    );
  }

  logout(): Promise<boolean> {
    localStorage.removeItem(this.JWT_TOKEN_KEY);

    this.userId = void 0;
    this.globalPermissionList = null;

    this.setUserData(undefined);

    this.authorized$.emit(false);

    return this.navigateToHomePage();
  }

  async navigateSafe(navigationAction: (router: Router) => Promise<boolean>) {
    await this.notificationService.hideAll();
    return await navigationAction(this.router);
  }

  navigateToHomePage() {
    const redirectUrl = this.getRedirectUrl();
    return this.getToken()
      ? this.navigateSafe((router) => router.navigateByUrl('/' + redirectUrl))
      : this.navigateSafe((router) => router.navigate(['/login']));
  }

  navigateToFirstAvailablePage() {
    const firstAvailableRoute = this.getFirstAvailableRoute();
    this.navigateSafe((router) => router.navigateByUrl(firstAvailableRoute));
  }

  setToken(token?: string | null | undefined): void {
    if (typeof token !== 'undefined') {
      localStorage.setItem(this.JWT_TOKEN_KEY, token!);
    } else {
      localStorage.removeItem(this.JWT_TOKEN_KEY);
    }
  }
  getToken(): string | undefined {
    const value = localStorage.getItem(this.JWT_TOKEN_KEY);
    return value !== null ? value : void 0;
  }

  setCurrentLanguage(language?: string): void {
    if (typeof language !== 'undefined') {
      localStorage.setItem(this.CURRENT_LANGUAGE_KEY, language);
    } else {
      localStorage.removeItem(this.CURRENT_LANGUAGE_KEY);
    }
  }
  getCurrentLanguage(): string | undefined {
    const value = localStorage.getItem(this.CURRENT_LANGUAGE_KEY);
    return value !== null ? value : void 0;
  }

  getUserId(): number | undefined {
    return this.userId;
  }

  globalPermissionGranted(permission: number) {
    return this.getGlobalPermissionArray().includes(permission);
  }

  getGlobalPermissionArray(): GlobalPermissions[] {
    if (this.globalPermissionList == null) {
      return [];
    }

    return this.globalPermissionList
      .split(',')
      .map((pn) => <GlobalPermissions>parseInt(GlobalPermissions[<any>pn])
      )
  }

  getAppVersion(): string {
    return environment.version;
  }

  getMaxPageSize(): number | undefined {
    return this.maxPageSize;
  }

  getShiftLength(): number {
    return this.shiftLength!;
  }

  get sideNavShown() {
    return localStorage.getItem(this.SIDENAV_SHOWN_KEY) === 'true';
  }

  set sideNavShown(value: boolean) {
    localStorage.setItem(this.SIDENAV_SHOWN_KEY, JSON.stringify(value));
  }

  toggleSideNavShown() {
    this.sideNavShown = !this.sideNavShown;
    this.sideNavShown$.emit(this.sideNavShown);
  }

  get sideNavSlimmed() {
    return localStorage.getItem(this.SIDENAV_SLIMMED_KEY) === 'true';
  }

  set sideNavSlimmed(value: boolean) {
    localStorage.setItem(this.SIDENAV_SLIMMED_KEY, JSON.stringify(value));
  }

  toggleSideNavSlimmed() {
    this.sideNavSlimmed = !this.sideNavSlimmed;
    this.sideNavSlimmed$.emit(this.sideNavSlimmed);
  }
  setSideNavSlimmed(slim: boolean) {
    this.sideNavSlimmed = slim;
    this.sideNavSlimmed$.emit(this.sideNavSlimmed);
  }

  saveRowSelectionMode(tableName: string, selectionMode: boolean) {
    localStorage.setItem(
      this.SELECTION_MODE_KEY_PREFIX + tableName,
      JSON.stringify(selectionMode)
    );
  }
  getRowSelectionMode(tableName: string) {
    try {
      const selectionMode = localStorage.getItem(
        this.SELECTION_MODE_KEY_PREFIX + tableName
      );
      return selectionMode !== null && selectionMode !== undefined
        ? JSON.parse(selectionMode)
        : [];
    } catch {
      return [];
    }
  }
  saveRowSelection(tableName: string, values: any[]) {
    localStorage.setItem(
      this.SELECTED_ROWS_KEY_PREFIX + tableName,
      JSON.stringify(values)
    );
  }

  getRowSelection(tableName: string): any[] {
    try {
      const values = localStorage.getItem(
        this.SELECTED_ROWS_KEY_PREFIX + tableName
      );
      return values !== null && values !== undefined ? JSON.parse(values) : [];
    } catch {
      return [];
    }
  }
  clearAllRowSelection() {
    this.clearAllKeysWithPrefix(this.SELECTED_ROWS_KEY_PREFIX);
  }
  saveFilterStates(tableName: string, values: any) {
    localStorage.setItem(
      this.FILTERS_KEY_PREFIX + tableName,
      JSON.stringify(values)
    );
  }
  getFilterStates<T>(tableName: string): T | null {
    const values = localStorage.getItem(this.FILTERS_KEY_PREFIX + tableName);
    return values !== null && values !== undefined
      ? <T>JSON.parse(values)
      : null;
  }
  clearAllFilterStates() {
    this.clearAllKeysWithPrefix(this.FILTERS_KEY_PREFIX);
  }
  clearAllKeysWithPrefix(prefix: string) {
    for (var key in localStorage) {
      if (key.indexOf(prefix) == 0) {
        localStorage.removeItem(key);
      }
    }
  }
  setUserState(userState: UserState) {
    const userData = this.getUserData();
    if(userData) {
      userData.state = userState;
      this.setUserData(userData);
    }
  }
  setUserData(user: LoginResponseUserDataModel | undefined) {
    if (user !== undefined) {
      localStorage.setItem(this.USERDATA_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(this.USERDATA_KEY);
    }
  }
  getUserData(): LoginResponseUserDataModel | undefined {
    const user = localStorage.getItem(this.USERDATA_KEY);
    if (user !== null && user !== undefined) {
      return JSON.parse(user);
    } else {
      return undefined;
    }
  }
  setUserPreferenceData(userPreference: PreferenceData | undefined) {
    if (userPreference !== undefined) {
      localStorage.setItem(
        this.USER_PREFERENCE_KEY,
        JSON.stringify(userPreference)
      );
    } else {
      localStorage.removeItem(this.USER_PREFERENCE_KEY);
    }
  }
  getUserPreferenceData(): PreferenceData | undefined {
    const preference = localStorage.getItem(this.USER_PREFERENCE_KEY);
    if (preference !== null && preference !== undefined) {
      return JSON.parse(preference);
    } else {
      return undefined;
    }
  }
  isDarkMode(): boolean {
    let preferenceData = this.getUserPreferenceData();
    if(preferenceData !== undefined && preferenceData.themeId) {
      let newThemeKey = Object.keys(Theme)[Object.values(Theme).indexOf(preferenceData.themeId as any)];
      let newTheme: Theme = Theme[newThemeKey];

      return newTheme === Theme.Dark;
    }

    return false;
  }
  getUserStateData(): Observable<UserData> {
    return this.userService.userGetUserState({
      Id: this.getUserId(),
    });
  }
  setRedirectUrl(url: string | null) {
    if (url !== null) {
      localStorage.setItem(this.REDIRECTURL_KEY, url);
    } else {
      localStorage.removeItem(this.REDIRECTURL_KEY);
    }
  }
  getRedirectUrl(): string | null {
    return localStorage.getItem(this.REDIRECTURL_KEY);
  }

  public searchTextChanged(text: string) {
    this.searchTextChanged$.emit(text);
  }
  public getBuiltInLabelName() {
    return this.BuiltInLabelName;
  }
  public getBuiltInCompanyName() {
    return this.BuiltInCompanyName;
  }
  public getDefaultCountryName() {
    return this.DefaultCountryName;
  }
  public getDefaultNationality() {
    return this.DefaultNationality;
  }
  public getAdvertisementLinkPrefix() {
    return this.AdvertisementLinkPrefix;
  }
  public getRegistrationLinkPrefix() {
    return this.RegistrationLinkPrefix;
  }
  public getBaseUrl() {
    return this.BaseUrl;
  }

  public setRecaptchaSiteKey(siteKey: string) {
    this.recaptchaSiteKey = siteKey;
  }
  public getRecaptchaSiteKey() {
    return this.recaptchaSiteKey;
  }
  setUserViewLabels(labels: LabelData[] | null) {
    if (labels !== null) {
      localStorage.setItem(this.USERVIEW_LABELS_KEY, JSON.stringify(labels));
    } else {
      localStorage.removeItem(this.USERVIEW_LABELS_KEY);
    }
  }
  getUserViewLabels(): LabelData[] | null {
    const labels = localStorage.getItem(this.USERVIEW_LABELS_KEY);
    if (labels !== null && labels !== undefined) {
      return JSON.parse(labels);
    } else {
      return null;
    }
  }
  public versionMismatch(version: string) {
    this.versionMismatch$.emit(version);
  }
  public manualNotAccepted(manuals?: ManualData[]) {
    this.manualNotAccepted$.emit(manuals);
    this.notAcceptedManuals = manuals;
  }
  public saveNotAcceptedManuals(manuals: ManualData[] | null | undefined) {
    localStorage.setItem('NotAcceptedManuals', JSON.stringify(manuals));
  }
  public resetNotAcceptedManuals() {
    localStorage.removeItem('NotAcceptedManuals');
  }
  public getNotAcceptedManuals(): ManualData[] | null {
    let json = localStorage.getItem('NotAcceptedManuals');
    return json ? JSON.parse(json) : null;
  }
  public getUserState(): UserState | null | undefined {
    return this.userData !== null ? this.userData.state : null;
  }
}
