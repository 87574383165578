/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateUserCardRoleCommand } from '../models/create-or-update-user-card-role-command';
import { RemoveUserCardRoleCommand } from '../models/remove-user-card-role-command';
import { UserCardRoleData } from '../models/user-card-role-data';

@Injectable({
  providedIn: 'root',
})
export class UserCardRolesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userCardRolesGet
   */
  static readonly UserCardRolesGetPath = '/api/UserCardRoles/Get';

  /**
   * Eseményben betölthető funkciók lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardRolesGet$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserCardRoleData>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardRolesService.UserCardRolesGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserCardRoleData>>;
      })
    );
  }

  /**
   * Eseményben betölthető funkciók lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardRolesGet$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserCardRoleData>> {

    return this.userCardRolesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserCardRoleData>>) => r.body as Array<UserCardRoleData>)
    );
  }

  /**
   * Eseményben betölthető funkciók lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardRolesGet$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserCardRoleData>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardRolesService.UserCardRolesGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserCardRoleData>>;
      })
    );
  }

  /**
   * Eseményben betölthető funkciók lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardRolesGet(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserCardRoleData>> {

    return this.userCardRolesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserCardRoleData>>) => r.body as Array<UserCardRoleData>)
    );
  }

  /**
   * Path part for operation userCardRolesPost
   */
  static readonly UserCardRolesPostPath = '/api/UserCardRoles/Post/Post';

  /**
   * Eseményben betölthető funkció létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardRolesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardRolesPost$Response(params?: {
    body?: CreateOrUpdateUserCardRoleCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardRolesService.UserCardRolesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Eseményben betölthető funkció létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardRolesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardRolesPost(params?: {
    body?: CreateOrUpdateUserCardRoleCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userCardRolesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userCardRolesRemove
   */
  static readonly UserCardRolesRemovePath = '/api/UserCardRoles/Remove/Remove';

  /**
   * Eseményben betölthető funkció törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardRolesRemove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardRolesRemove$Response(params?: {
    body?: RemoveUserCardRoleCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardRolesService.UserCardRolesRemovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Eseményben betölthető funkció törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardRolesRemove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardRolesRemove(params?: {
    body?: RemoveUserCardRoleCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userCardRolesRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
