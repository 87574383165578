import { Pipe, PipeTransform } from '@angular/core';
import { MonitoringService } from '../../core/providers/monitoring.service';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {
constructor(
    private monitoringService: MonitoringService) { 
    }

  transform(value: string): string {
    this.monitoringService.logTrace('LogPipe', { value: value});

    return value;
  }
}