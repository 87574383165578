<h2 mat-dialog-title *ngIf="message.title">
  {{message.title}}
</h2>
<mat-dialog-content cdkFocusInitial>
  <div class="dialog-content">
    <mat-icon [class]="message.type">{{getIconByMessageType()}}</mat-icon>
    <div>
      <div class="text" [innerHTML]="message.message">
      </div>
      <div class="additional-info" innerHTML="{{message.info}}">
        <!-- {{message.info}} -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngFor="let button of message.buttons; index as i">
    <button mat-raised-button mat-dialog-close
            [color]="getButtonColor(i, button)"
            (click)="close(button)"
            [style.background-color]="getButtonBgColor(i, button)">{{getButtonText(i, button)}}</button>
    <!--<button mat-raised-button color="primary" mat-dialog-close *ngSwitchCase="'ok'" (click)="close(button)">{{'UI.Label.OK|OK' | translate}}</button>
    <button mat-raised-button color="accent" mat-dialog-close *ngSwitchCase="'no'" (click)="close(button)">{{'UI.Label.No|Nem' | translate}}</button>
    <button mat-raised-button color="primary" mat-dialog-close *ngSwitchCase="'yes'" (click)="close(button)">{{'UI.Label.Yes|Igen' | translate}}</button>
    <button mat-raised-button color="accent" mat-dialog-close *ngSwitchCase="'delete'" (click)="close(button)">{{'UI.Label.Delete|Törlés' | translate}}</button>
    <button mat-raised-button color="accent" mat-dialog-close *ngSwitchCase="'close'" (click)="close(button)">{{'UI.Label.Close|Bezár' | translate}}</button>-->
  </ng-container>
  <div class="footer-info" *ngIf="message.footerInfo && message.footerInfo.length > 0">
    {{message.footerInfo}}
  </div>
</mat-dialog-actions>
