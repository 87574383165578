/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetUserInfoResult } from '../models/get-user-info-result';
import { LoginRequestModel } from '../models/login-request-model';
import { LoginRequestSocialModel } from '../models/login-request-social-model';
import { LoginResponseModel } from '../models/login-response-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = '/api/Auth/Login';

  /**
   * Bejelentkezés felhasználói név és jelszó segítségével.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login$Plain$Response(params?: {
    body?: LoginRequestModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoginResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseModel>;
      })
    );
  }

  /**
   * Bejelentkezés felhasználói név és jelszó segítségével.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login$Plain(params?: {
    body?: LoginRequestModel
  },
  context?: HttpContext

): Observable<LoginResponseModel> {

    return this.login$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoginResponseModel>) => r.body as LoginResponseModel)
    );
  }

  /**
   * Bejelentkezés felhasználói név és jelszó segítségével.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login$Response(params?: {
    body?: LoginRequestModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoginResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseModel>;
      })
    );
  }

  /**
   * Bejelentkezés felhasználói név és jelszó segítségével.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login(params?: {
    body?: LoginRequestModel
  },
  context?: HttpContext

): Observable<LoginResponseModel> {

    return this.login$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoginResponseModel>) => r.body as LoginResponseModel)
    );
  }

  /**
   * Path part for operation loginSocial
   */
  static readonly LoginSocialPath = '/api/Auth/LoginSocial';

  /**
   * Bejelentkezés Google user segítségével.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginSocial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  loginSocial$Plain$Response(params?: {
    body?: LoginRequestSocialModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoginResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginSocialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseModel>;
      })
    );
  }

  /**
   * Bejelentkezés Google user segítségével.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginSocial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  loginSocial$Plain(params?: {
    body?: LoginRequestSocialModel
  },
  context?: HttpContext

): Observable<LoginResponseModel> {

    return this.loginSocial$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoginResponseModel>) => r.body as LoginResponseModel)
    );
  }

  /**
   * Bejelentkezés Google user segítségével.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginSocial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  loginSocial$Response(params?: {
    body?: LoginRequestSocialModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoginResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginSocialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseModel>;
      })
    );
  }

  /**
   * Bejelentkezés Google user segítségével.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginSocial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  loginSocial(params?: {
    body?: LoginRequestSocialModel
  },
  context?: HttpContext

): Observable<LoginResponseModel> {

    return this.loginSocial$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoginResponseModel>) => r.body as LoginResponseModel)
    );
  }

  /**
   * Path part for operation check
   */
  static readonly CheckPath = '/api/Auth';

  /**
   * A bejelentkezett felhasználó adatainak lekérése, vagy a munkamenet ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `check$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  check$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GetUserInfoResult>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.CheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserInfoResult>;
      })
    );
  }

  /**
   * A bejelentkezett felhasználó adatainak lekérése, vagy a munkamenet ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `check$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  check$Plain(params?: {
  },
  context?: HttpContext

): Observable<GetUserInfoResult> {

    return this.check$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<GetUserInfoResult>) => r.body as GetUserInfoResult)
    );
  }

  /**
   * A bejelentkezett felhasználó adatainak lekérése, vagy a munkamenet ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `check()` instead.
   *
   * This method doesn't expect any request body.
   */
  check$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GetUserInfoResult>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.CheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserInfoResult>;
      })
    );
  }

  /**
   * A bejelentkezett felhasználó adatainak lekérése, vagy a munkamenet ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `check$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  check(params?: {
  },
  context?: HttpContext

): Observable<GetUserInfoResult> {

    return this.check$Response(params,context).pipe(
      map((r: StrictHttpResponse<GetUserInfoResult>) => r.body as GetUserInfoResult)
    );
  }

}
