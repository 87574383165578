/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ExceptionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation exceptionThrow
   */
  static readonly ExceptionThrowPath = '/api/Exception/Throw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exceptionThrow()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionThrow$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExceptionService.ExceptionThrowPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exceptionThrow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionThrow(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.exceptionThrow$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation exceptionThrow2
   */
  static readonly ExceptionThrow2Path = '/api/Exception/Throw2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exceptionThrow2()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionThrow2$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExceptionService.ExceptionThrow2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exceptionThrow2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionThrow2(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.exceptionThrow2$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation exceptionLogInfo
   */
  static readonly ExceptionLogInfoPath = '/api/Exception/LogInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exceptionLogInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionLogInfo$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExceptionService.ExceptionLogInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exceptionLogInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionLogInfo(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.exceptionLogInfo$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation exceptionLogWarning
   */
  static readonly ExceptionLogWarningPath = '/api/Exception/LogWarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exceptionLogWarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionLogWarning$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExceptionService.ExceptionLogWarningPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exceptionLogWarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exceptionLogWarning(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.exceptionLogWarning$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
