export enum EventUserPresenceState {
  NotAppeared = 0,
  Present = 1,
  Late = 2,
  Resigned24 = 3,
  Resigned48 = 4,
  Resigned = 5,
  Storno = 6,
}

