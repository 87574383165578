import { APP_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { I18NService } from '../api/services/i-18-n.service';
import { AppComponent } from './app.component';
import { CustomTranslateParser } from './shared/classes/custom-translate-parser';
import { HttpTranslateLoader } from './shared/classes/http-translate-loader';
import { CoreModule } from './core/core.module';
import { SessionProvider } from './core/providers/session.provider';
import { SharedModule } from './shared/shared.module';
import { TranslationLoaderResolver } from './translation-loader.resolver';
import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import { IConfig, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: 'login',
          loadChildren: () =>
            import('./authentication/authentication.module').then(
              (m) => m.AuthenticationModule
            ),
        },
        {
          path: 'ad/:id',
          loadChildren: () =>
            import('./apply/apply.module').then((m) => m.ApplyModule),
        },
        {
          path: 'ad',
          loadChildren: () =>
            import('./authentication/authentication.module').then(
              (m) => m.AuthenticationModule
            ),
        },
        {
          path: 'reg/:id',
          loadChildren: () =>
            import('./registration/registration.module').then(
              (m) => m.RegistrationModule
            ),
        },
        {
          path: 'reg',
          loadChildren: () =>
            import('./authentication/authentication.module').then(
              (m) => m.AuthenticationModule
            ),
        },
        {
          path: 'pass/:id',
          loadChildren: () =>
            import('./lostpassword/lostpassword.module').then(
              (m) => m.LostPasswordModule
            ),
        },
        {
          path: '',
          resolve: { model: TranslationLoaderResolver },
          loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
        },
      ],
      {
          initialNavigation: 'disabled',
          onSameUrlNavigation: 'reload'
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (i18nService: I18NService) =>
          new HttpTranslateLoader(i18nService),
        deps: [I18NService],
      },
      parser: {
        provide: TranslateParser,
        useFactory: () => new CustomTranslateParser(),
      },
    }),
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: APP_ID, useValue: 'ng-cli-universal' },
    provideEnvironmentNgxMask(maskConfig),
    provideNgxMask(),
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useFactory: (sessionProvider: SessionProvider) => {
        const offset = sessionProvider.getUserPreferenceData()?.timeZoneOffset;
        return offset; //example: UTC +2
      },
      deps: [SessionProvider]
    },
    TranslationLoaderResolver,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}