import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../api/services/user.service';
import { RegistrationService } from '../../../../api/services';
import { UniquenessType, ValidationType } from '../../../../api/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { trailingSpaceValidator } from '../../validators/trailing-space-validator';
import { Subject, timer } from 'rxjs';
import { emailTLDValidator } from '../../validators/email-tld-validator';
import { uniquenessValidator } from '../../validators/uniqueness-validator';

@Component({
  selector: 'contactfield-validation',
  templateUrl: './contactfield-validation.component.html',
  styleUrls: ['./contactfield-validation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactFieldValidationComponent implements OnInit, OnDestroy {
  protected readonly _destroy = new Subject<void>();

  @ViewChild('validationCodeInput') set validation(validationInputRef: ElementRef) {
    if (validationInputRef) {
      this.validationCodeInput = validationInputRef;
      timer(200).subscribe(x => {
        this.validationCodeInput.nativeElement.focus();
      });
    }
  }
  validationCodeInput: ElementRef;

  @ViewChild('emailInputField') emailInput: ElementRef;
  @ViewChild('phoneNumberInputField') phoneNumberInput: ElementRef;

  validationSent = false;
  userId = null;
  validationType: ValidationType;
  phonenumber: string;
  email: string;
  emailValidationType = ValidationType.EmailAddress;
  smsValidationType = ValidationType.PhoneNumber;
  firstname: string;
  surname: string;

  constructor(
    private registrationService: RegistrationService,
    private translator: TranslateService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.validationType = data.validationType;
    this.userId = data.userId;
    this.phonenumber = data.phonenumber ?? '';
    this.email = data.email;
    this.firstname = data.firstname;
    this.surname = data.surname;
  }

  public validationForm = new UntypedFormGroup({
    email: new UntypedFormControl(null, [trailingSpaceValidator()]), // validátorok beállítása az Oninitben
    phonenumber: new UntypedFormControl('', [trailingSpaceValidator()]),
    validationCode: new UntypedFormControl(null, [Validators.required]),
    confirmed: new UntypedFormControl(false, [Validators.requiredTrue]),
    validationType: new UntypedFormControl()
  });

  hide: boolean = true;

  validateButtonDisabled(): boolean {
    return this.validationType === ValidationType.EmailAddress
      ? this.validationForm.controls['email'].invalid
      : this.validationForm.controls['phonenumber'].invalid;

  }
  validate() {
    this.validationForm.controls['validationCode'].setValue('');
    this.registrationService.startValidation({
      body: {
        validationType: this.validationType,
        subject: this.validationType === ValidationType.EmailAddress
          ? this.validationForm.controls['email'].value
          : this.validationForm.controls['phonenumber'].value,
        firstName: this.firstname,
        surname: this.surname,
        userId: this.userId
      }
    }).subscribe(result => {
      this.validationSent = true;
      this.validationForm.controls['validationCode'].setErrors(null);
      //timer(400).subscribe(x => {
      //  this.validationCodeInput.nativeElement.focus();
      //});

    });
  }
  submitValidationCode() {
    this.registrationService.checkValidationCode({
      body: {
        validationType: this.validationType,
        subject: this.validationType === ValidationType.EmailAddress
          ? this.validationForm.controls['email'].value
          : this.validationForm.controls['phonenumber'].value,
        code: this.validationForm.controls['validationCode'].value,
        userId: this.userId
      }
    }).subscribe(result => {
      if (result === true) {
        this.validationForm.controls['confirmed'].setValue(result);
        this.validationForm.updateValueAndValidity();
      } else {
        this.validationForm.controls['validationCode'].setErrors({ 'invalidCode': true });
      }
    });
  }
  getCodePrefix() {
    return this.validationType === ValidationType.EmailAddress
      ? 'ME-'
      : 'MT-';
  }
  getValidationSubjectLabel(): string {
    return this.validationType === ValidationType.EmailAddress
      ? this.translator.instant('UI.Label.ValidationLabel.Email|E-mail cím')
      : this.translator.instant('UI.Label.ValidationLabel.Phonenumber|Telefonszám');
  }
  validationError(): boolean {
    return this.validationForm.controls['validationCode'].getError('invalidCode') !== undefined &&
      this.validationForm.controls['validationCode'].getError('invalidCode') !== null;
  }
  getValidationButtonText() {
    return this.validationError() === false
      ? this.translator.instant('UI.Validation.VerifyButton | Hitelesítés')
      : this.translator.instant('UI.Validation.VerifyButtonGetNewCode | Új hitelesítőkód')
  }
  getInstructionLabel(): string {
    return this.validationType === ValidationType.EmailAddress
      ? this.translator.instant('UI.Label.ValidationInstructionLabel.Email | Hamarosan küldünk egy e-mail-t. Az abban található 4 jegyű kódot írd be.')
      : this.translator.instant('UI.Label.ValidationInstructionLabel.Phonenumber | Hamarosan küldünk egy SMS-t. Az abban található 4 jegyű kódot írd be.');
  }
  changeSubject() {
    this.validationSent = false;
    this.validationForm.controls['validationCode'].setValue('');
    this.validationForm.controls['confirmed'].setValue(false);
    this.validationForm.controls['email'].setValue(null);
    this.validationForm.controls['phonenumber'].setValue('');
  }
  subjectEnterEvent() {
    if (this.validateButtonDisabled() === false) {
      this.validate();
    }
  }
  validationCodeEnterEvent() {
    if (this.validationForm.controls['validationCode'].valid) {
      this.submitValidationCode();
    }
  }
  ngOnInit(): void {
    if (this.validationType === ValidationType.EmailAddress) {
      this.validationForm.controls['email'].setValidators(
        [Validators.email, emailTLDValidator(), Validators.required, trailingSpaceValidator()]);
      this.validationForm.controls['email'].setAsyncValidators(
        [uniquenessValidator(this.userService, UniquenessType.Email, () => this.userId)]
      );
      this.validationForm.controls['email'].setValue(this.email);
      this.validationForm.controls['validationType'].setValue(this.validationType);
      timer(200).subscribe(x => {
        this.emailInput.nativeElement.focus();
      });

    } else {
      this.validationForm.controls['phonenumber'].setValidators([Validators.required, trailingSpaceValidator()]);
      this.validationForm.controls['phonenumber'].setValue(this.phonenumber);
      timer(200).subscribe(x => {
        this.phoneNumberInput.nativeElement.focus();
      });

    }
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
