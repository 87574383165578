import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { I18NService } from '../../../api/services/i-18-n.service';
import { shareReplay } from 'rxjs/operators';

export class HttpTranslateLoader extends TranslateLoader {
  constructor(private i18nService: I18NService) {
    super();
  }

  //cache$: Observable<Object> = null;
  //cachedLang: string = null;

  getTranslation(lang: string): Observable<any> {
    return this.i18nService.getTranslations({ langCode: lang });
  }
}
