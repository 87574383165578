/* tslint:disable */
/* eslint-disable */
export enum AutomaticNotificationTypes {
    UserRegistration = 1,
    UserRegistrationSucceeded = 2,
    EmailVerification = 3,
    EmailChange = 4,
    EmployerRegistrationSucceeded = 5,
    ForgottenPassword = 6,
    EventResigned = 7,
    EventResignedNotificationFailedToSend = 8,
    PhoneVerification = 9
}