import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const pass1 = control.get('password1');
  const pass2 = control.get('password2');

  return pass1 && pass2 && pass1.value === pass2.value
    ? null
    : { confirmPassword: true };
};
