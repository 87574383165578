<div [ngClass]="style?.cssClass || 'card'">
    <div [ngClass]="style?.header?.cssClass || 'card-header d-flex align-items-center'" *ngIf="title">
        <ng-container *ngTemplateOutlet="headerTemplate || defaultHeaderTemplate; context:{title: title, defaultHeaderTemplate: defaultHeaderTemplate}"></ng-container>
    </div>

    <div [ngClass]="style?.content?.cssClass || 'card-body'">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <div [ngClass]="style?.footer?.cssClass || 'card-footer d-flex align-items-center'" *ngIf="editing">
        <ng-container *ngTemplateOutlet="footerTemplate || defaultFooterTemplate; context:{defaultFooterTemplate: defaultFooterTemplate}"></ng-container>
    </div>
</div>

<ng-template #defaultHeaderTemplate let-title="title">
    <div class="card-title flex-fill mb-0 text-capitalize-first">{{ title }}</div>
    <ng-container *ngTemplateOutlet="editButtonTemplate"></ng-container>
</ng-template>

<ng-template #defaultFooterTemplate>
    <div [ngClass]="style?.footer?.groupCssClass">
        <button *ngIf="saveButtonState.visible" type="button" [ngClass]="saveButtonState.cssClass || 'btn btn-primary mr-lg-2'" (click)="save()" [disabled]="saveButtonState.disabled || !canSave ? true : null">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            {{ 'UI.Label.Save|Mentés' | translate }}
        </button>
        <button *ngIf="cancelButtonState.visible" type="button" [ngClass]="cancelButtonState.cssClass || 'btn btn-secondary'" (click)="cancel()" [disabled]="cancelButtonState.disabled ? true : null">
            <i class="fa fa-ban" aria-hidden="true"></i>
            {{ 'UI.Label.Cancel|Mégsem' | translate }}
        </button>
        <button *ngIf="okButtonState.visible" type="button" [ngClass]="okButtonState.cssClass || 'btn btn-primary'" (click)="cancel()" [disabled]="okButtonState.disabled ? true : null">
            <i class="fa fa-check" aria-hidden="true"></i>
            {{ 'UI.Label.OK|OK' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #editButtonTemplate let-class>
    <button *ngIf="editButtonState.visible" [ngClass]="editButtonState.cssClass || 'btn btn-outline-secondary'" (click)="edit(false)" [attr.disabled]="editButtonState.disabled || editing ? true : null">
        <i class="fa fa-pencil" aria-hidden="true"></i>
    </button>
</ng-template>

<ng-template #newButtonTemplate let-class>
    <button *ngIf="newButtonState.visible" [ngClass]="newButtonState.cssClass || 'btn btn-outline-secondary'" (click)="edit(true)" [attr.disabled]="newButtonState.disabled || editing ? true : null">
        <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
</ng-template>
