import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UINotificationService } from '../../core/providers/ui-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionProvider } from '../../core/providers/session.provider';

export interface ComponentCanDeactivate {
    canDeactivateWhileWorkingProcess: () => boolean | Observable<boolean>;
}

@Injectable()
export class WorkInProcessGuard {
    constructor(
      private notificationService: UINotificationService,
      private translator: TranslateService,
      private session: SessionProvider) {
    }
    canDeactivate(component: ComponentCanDeactivate): boolean | Promise<boolean> {
      if (component.canDeactivateWhileWorkingProcess()) {
        return true;
      } else {
  
        return new Promise<boolean>(async (resolve) => {
          const result = await this.notificationService.showModalMessage({
            message: this.translator.instant('UI.Common.WorkInProcess.Info|Még dolgozunk. Várj néhány másodpercet a kilépéssel.'),
            buttons: ['ok'],
            buttonBgColor: [this.session.isDarkMode() ? '#424242' : '#fff'],
            buttonColor: ['#000', 'accent'],
            type: 'warning',
          });
        });
  
        
      }
    }
}