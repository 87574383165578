Array.prototype.contentEquals = function <T>(array: T[] | undefined, comparer?: (x: T, y: T) => boolean) {
    if (!array) {
        return false;
    }

    if (this.length !== array.length) {
        return false;
    }

    if (!comparer) {
        comparer = (x, y) => x === y;
    }

    for (let i = 0, n = this.length; i < n; i++) {
        if (!comparer(this[i], array[i])) {
            return false;
        }
    }

    return true;
};

Object.defineProperty(Array.prototype, 'contentEquals', { enumerable: false });
