import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { SessionProvider } from '../../app/core/providers/session.provider';
import { PictureType } from '../enums/picture-type';

@Injectable()
export class UserImagesService {
  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer) {
  }

  public getUserImageAsInlineImage(params: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
  }, success: (result: string) => void): void {
    this.getUserImageAsBlob(params).subscribe(data => {
      this.createInlineImageFromBlob(data, (result) => {
        success(result);
      });
    })
  }

  public getUserImage(params: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
  }, success: (result: SafeUrl) => void, error: (_) => void): void {
    this.getUserImageAsBlob(params).subscribe(data => {
      if (data !== null) {
        this.createImageFromBlob(data, (result) => {
          success(result);
        });
      } else {
        error(null);
      }
    })
  }

  public getUserImageBlob(params: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
  }, success: (result: Blob) => void, error: (_) => void) {
    this.getUserImageAsBlob(params).subscribe(data => {
      if (data !== null) {
        success(data);
      } else {
        error(null);
      }
    })
  }

  private getUserImageAsBlob(params: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
  }): Observable<Blob> {
    let httpParams = new HttpParams();

    if (params.UserId !== undefined && params.UserId !== null) {
      httpParams = httpParams.append('userId', params.UserId.toString());
    }

    if (params.PictureType !== undefined && params.PictureType !== null) {
      httpParams = httpParams.append('pictureType', params.PictureType.toString());
    }

    if (params.Thumbnail !== undefined && params.Thumbnail !== null) {
      httpParams = httpParams.append('thumbnail', params.Thumbnail.toString());
    }

    if (params.UseDefaultIfNotExists !== undefined && params.UseDefaultIfNotExists !== null) {
      httpParams = httpParams.append('useDefaultIfNotExists', params.UseDefaultIfNotExists.toString())
    }

    return this.http.get('/api/User/GetUserImage', { responseType: 'blob', params: httpParams });
  }

  private createInlineImageFromBlob(image: Blob, success: (result: string) => void) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      success(reader.result as string);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  private createImageFromBlob(image: Blob, success: (result: SafeUrl) => void) {
    const objectURL = URL.createObjectURL(image);
    const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    success(img);
  }
}
