<router-outlet></router-outlet>

<div id="boot-content" class="container">
    <div class="logobg"><span></span></div>
</div>
<ng-container *ngTemplateOutlet="modalwaitTemplate; context: {$implicit: modalState$ | async}"></ng-container>

<ng-template #modalwaitTemplate let-modalState>
    <div class="modalwait" *ngIf="modalState?.show" [@fadeInOut]>
        <div class="background"></div>
        <div class="cs-loader">
            <div class="loadinglogo"></div>
            <span>{{ modalState.text }}</span>
            <div class="progress" *ngIf="modalState.showProgress && modalState.progress >= 0">
                <div class="progress-bar" role="progressbar" [style.width.%]="modalState.progress">{{modalState.progress + '%'}}</div>
            </div>
            <div class="cs-loader-inner" *ngIf="modalState.showProgress === false">
                <label> ●</label>
                <label> ●</label>
                <label> ●</label>
                <label> ●</label>
                <label> ●</label>
                <label> ●</label>
            </div>
        </div>
    </div>
</ng-template>
