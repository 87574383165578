
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UINotificationService } from '../../core/providers/ui-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionProvider } from 'src/app/core/providers/session.provider';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
  constructor(
    private notificationService: UINotificationService,
    private translator: TranslateService,
    private session: SessionProvider) {
  }
  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {

      return new Promise<boolean>(async (resolve) => {
        const result = await this.notificationService.showModalMessage({
          title: this.translator.instant('UI.Common.UnsavedChanges.Title|Módosítások elvetése'),
          message: this.translator.instant('UI.Common.UnsavedChanges.ConfirmLeaveQuestion|A módosításaid nem kerültek mentésre. Elveted a módosításokat?'),
          buttons: ['cancel', 'ok'],
          buttonBgColor: [this.session.isDarkMode() ? '#424242' : '#fff'],
          buttonColor: ['#000', 'accent'],
          type: 'warning',
          //buttonText: [this.translator.instant('UI.Label.Cancel'), this.translator.instant('UI.Label.YesSave | Igen, mentem')]
        });
        resolve(result === 'ok');
      });

      
    }
  }
}
