/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateNotificationTemplateCommand } from '../models/create-or-update-notification-template-command';
import { ListNotificationTemplatesQuery } from '../models/list-notification-templates-query';
import { ListNotificationTemplatesResult } from '../models/list-notification-templates-result';
import { NotificationTemplateData } from '../models/notification-template-data';
import { RemoveNotificationTemplateCommand } from '../models/remove-notification-template-command';

@Injectable({
  providedIn: 'root',
})
export class NotificationTemplatesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notificationTemplatesList
   */
  static readonly NotificationTemplatesListPath = '/api/NotificationTemplates/List';

  /**
   * Értesítés sablonok listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesList$Plain$Response(params?: {
    body?: ListNotificationTemplatesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListNotificationTemplatesResult>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListNotificationTemplatesResult>;
      })
    );
  }

  /**
   * Értesítés sablonok listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesList$Plain(params?: {
    body?: ListNotificationTemplatesQuery
  },
  context?: HttpContext

): Observable<ListNotificationTemplatesResult> {

    return this.notificationTemplatesList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListNotificationTemplatesResult>) => r.body as ListNotificationTemplatesResult)
    );
  }

  /**
   * Értesítés sablonok listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesList$Response(params?: {
    body?: ListNotificationTemplatesQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListNotificationTemplatesResult>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListNotificationTemplatesResult>;
      })
    );
  }

  /**
   * Értesítés sablonok listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesList(params?: {
    body?: ListNotificationTemplatesQuery
  },
  context?: HttpContext

): Observable<ListNotificationTemplatesResult> {

    return this.notificationTemplatesList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListNotificationTemplatesResult>) => r.body as ListNotificationTemplatesResult)
    );
  }

  /**
   * Path part for operation notificationTemplatesGet
   */
  static readonly NotificationTemplatesGetPath = '/api/NotificationTemplates/Get';

  /**
   * Értesítés sablon adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationTemplatesGet$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationTemplateData>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationTemplateData>;
      })
    );
  }

  /**
   * Értesítés sablon adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationTemplatesGet$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<NotificationTemplateData> {

    return this.notificationTemplatesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationTemplateData>) => r.body as NotificationTemplateData)
    );
  }

  /**
   * Értesítés sablon adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationTemplatesGet$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationTemplateData>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationTemplateData>;
      })
    );
  }

  /**
   * Értesítés sablon adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationTemplatesGet(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<NotificationTemplateData> {

    return this.notificationTemplatesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationTemplateData>) => r.body as NotificationTemplateData)
    );
  }

  /**
   * Path part for operation notificationTemplatesPost
   */
  static readonly NotificationTemplatesPostPath = '/api/NotificationTemplates/Post/Post';

  /**
   * Értesítés sablon létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesPost$Response(params?: {
    body?: CreateOrUpdateNotificationTemplateCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Értesítés sablon létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesPost(params?: {
    body?: CreateOrUpdateNotificationTemplateCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.notificationTemplatesPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation notificationTemplatesRemove
   */
  static readonly NotificationTemplatesRemovePath = '/api/NotificationTemplates/Remove/Remove';

  /**
   * Értesítés sablon törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationTemplatesRemove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesRemove$Response(params?: {
    body?: RemoveNotificationTemplateCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationTemplatesService.NotificationTemplatesRemovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Értesítés sablon törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationTemplatesRemove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationTemplatesRemove(params?: {
    body?: RemoveNotificationTemplateCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.notificationTemplatesRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
