/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeleteStatisticsCommand } from '../models/delete-statistics-command';
import { ExportStatisticsQuery } from '../models/export-statistics-query';
import { IActionResult } from '../models/i-action-result';
import { ImportStatisticsCommand } from '../models/import-statistics-command';
import { ImportedStatisticsLineResult } from '../models/imported-statistics-line-result';
import { ListStatisticsQuery } from '../models/list-statistics-query';
import { ListStatisticsResult } from '../models/list-statistics-result';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation statisticsList
   */
  static readonly StatisticsListPath = '/api/Statistics/List';

  /**
   * Statisztikák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsList$Plain$Response(params?: {
    body?: ListStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListStatisticsResult>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListStatisticsResult>;
      })
    );
  }

  /**
   * Statisztikák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsList$Plain(params?: {
    body?: ListStatisticsQuery
  },
  context?: HttpContext

): Observable<ListStatisticsResult> {

    return this.statisticsList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListStatisticsResult>) => r.body as ListStatisticsResult)
    );
  }

  /**
   * Statisztikák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsList$Response(params?: {
    body?: ListStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListStatisticsResult>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListStatisticsResult>;
      })
    );
  }

  /**
   * Statisztikák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsList(params?: {
    body?: ListStatisticsQuery
  },
  context?: HttpContext

): Observable<ListStatisticsResult> {

    return this.statisticsList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListStatisticsResult>) => r.body as ListStatisticsResult)
    );
  }

  /**
   * Path part for operation statisticsImport
   */
  static readonly StatisticsImportPath = '/api/Statistics/Import';

  /**
   * Statisztika importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsImport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsImport$Plain$Response(params?: {
    body?: ImportStatisticsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedStatisticsLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedStatisticsLineResult>>;
      })
    );
  }

  /**
   * Statisztika importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsImport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsImport$Plain(params?: {
    body?: ImportStatisticsCommand
  },
  context?: HttpContext

): Observable<Array<ImportedStatisticsLineResult>> {

    return this.statisticsImport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedStatisticsLineResult>>) => r.body as Array<ImportedStatisticsLineResult>)
    );
  }

  /**
   * Statisztika importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsImport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsImport$Response(params?: {
    body?: ImportStatisticsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedStatisticsLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedStatisticsLineResult>>;
      })
    );
  }

  /**
   * Statisztika importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsImport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsImport(params?: {
    body?: ImportStatisticsCommand
  },
  context?: HttpContext

): Observable<Array<ImportedStatisticsLineResult>> {

    return this.statisticsImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedStatisticsLineResult>>) => r.body as Array<ImportedStatisticsLineResult>)
    );
  }

  /**
   * Path part for operation statisticsDelete
   */
  static readonly StatisticsDeletePath = '/api/Statistics/Delete';

  /**
   * Kijelölt statistikák törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsDelete$Response(params?: {
    body?: DeleteStatisticsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt statistikák törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsDelete(params?: {
    body?: DeleteStatisticsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.statisticsDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation statisticsExport
   */
  static readonly StatisticsExportPath = '/api/Statistics/Export';

  /**
   * Statisztikák exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsExport$Plain$Response(params?: {
    body?: ExportStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IActionResult>;
      })
    );
  }

  /**
   * Statisztikák exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsExport$Plain(params?: {
    body?: ExportStatisticsQuery
  },
  context?: HttpContext

): Observable<IActionResult> {

    return this.statisticsExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IActionResult>) => r.body as IActionResult)
    );
  }

  /**
   * Statisztikák exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsExport$Response(params?: {
    body?: ExportStatisticsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.StatisticsExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IActionResult>;
      })
    );
  }

  /**
   * Statisztikák exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  statisticsExport(params?: {
    body?: ExportStatisticsQuery
  },
  context?: HttpContext

): Observable<IActionResult> {

    return this.statisticsExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<IActionResult>) => r.body as IActionResult)
    );
  }

}
