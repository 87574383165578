import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class ThemeService {
    themeSignal = signal<Theme>(Theme.Light);

    setTheme(theme: Theme) {
        this.themeSignal.set(theme);
    }

    toggleTheme() {
        this.themeSignal.update(value => value === Theme.Dark ? Theme.Light : Theme.Dark);
    }
}

export enum Theme {
    Dark = 'dark',
    Light = 'light',
}