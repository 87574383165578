import { Injectable } from "@angular/core";
import { ApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { SessionProvider } from "./session.provider";
import { Environment, EnvironmentService } from "./environment.service";

@Injectable({
    providedIn: 'root',
  })
export class MonitoringService {
    
    constructor(
      private sessionProvider: SessionProvider,
      private environmentService: EnvironmentService) { 

        if (environmentService.getEnvironment() === Environment.Development) {
          return;
        }

        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: "0d133a44-113f-4576-a78a-e5ef9c34e693",
            enableAutoRouteTracking: true
          }
        });

        this.appInsights.loadAppInsights();
      }

  private readonly appInsights: ApplicationInsights;

  logPageView(name?: string, url?: string) {
    if (!this.appInsightsAvailable()) {
      return;
    }

    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (!this.appInsightsAvailable()) {
      return;
    }

    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (!this.appInsightsAvailable()) {
      return;
    }

    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    if (!this.appInsightsAvailable()) {
      return;
    }

    var customProperties: ICustomProperties = {};
    var userData = this.sessionProvider.getUserData();

    customProperties['userName'] = userData != undefined ? userData.name : 'unknown';

    customProperties['EnvironmentName'] = this.environmentService.getEnvironment();

    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, customProperties);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (!this.appInsightsAvailable()) {
      return;
    }
    
    this.appInsights.trackTrace({ message: message}, properties);
  }

  appInsightsAvailable() {
    return this.appInsights !== null && this.appInsights !== undefined;
  }
}