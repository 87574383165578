import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionProvider } from '../../app/core/providers/session.provider';
import { ExportAttendancesQuery } from '../models/export-attendances-query';
import { ExportHrStatisticsQuery } from '../models/export-hr-statistics-query';
import { ExportStatisticsQuery } from '../models/export-statistics-query';
import { ListUsersQuery } from '../models/list-users-query';
import { ExportLogEventsQuery } from '../models';

@Injectable()
export class ExportService {
  constructor(private http: HttpClient, private session: SessionProvider) {
  }

  exportUsersMaster(params: ListUsersQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/User/Export', params, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportUserData(params: ListUsersQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/User/ExportUserData', params, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportEventUsers(eventId: number): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/Event/Export', {eventId: eventId}, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportAttendances(query: ExportAttendancesQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/Attendance/Export', query, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }
  exportUserStatistics(query: ExportStatisticsQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/Statistics/Export', query, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportHrStatistics(query: ExportHrStatisticsQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/HrStatistics/Export', query, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportLogEvents(query: ExportLogEventsQuery): Promise<any> {
    return new Promise<any>((resolve) =>
      this.http.post<any>('/api/LogEvent/Export', query, { responseType: 'blob' as 'json' }).subscribe(result => {
        resolve(result);
      })
    );
  }

  exportFileName(name: string, extension: string): string {
    const time = new Date().toISOString();
    let fileName = name;
    fileName += '.' + time + '.' + extension;
    return fileName
  }

}
