import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ApiModule } from '../../api/api.module';
import { GlobalErrorHandler } from './providers/global-error-handler';
import { UINotificationService } from './providers/ui-notification.service';
import { SessionProvider } from './providers/session.provider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MonitoringService } from './providers/monitoring.service';
import { EnvironmentService } from './providers/environment.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
        MatSnackBarModule,
        CommonModule,
        HttpClientModule,
        ApiModule,
    ],
})
export class CoreModule {
    // https://angular.io/guide/ngmodule-faq#what-is-the-forroot-method
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                GlobalErrorHandler,
                { provide: ErrorHandler, useExisting: GlobalErrorHandler },
                UINotificationService,
                MonitoringService,
                EnvironmentService,
                SessionProvider
            ]
        };
    }
}
