/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class PeriodicStatisticsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation periodicStatisticsCsv
   */
  static readonly PeriodicStatisticsCsvPath = '/api/PeriodicStatistics/Csv';

  /**
   * Statisztika csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodicStatisticsCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodicStatisticsCsv$Plain$Response(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodicStatisticsService.PeriodicStatisticsCsvPath, 'get');
    if (params) {
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Statisztika csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodicStatisticsCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodicStatisticsCsv$Plain(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.periodicStatisticsCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Statisztika csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodicStatisticsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodicStatisticsCsv$Response(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodicStatisticsService.PeriodicStatisticsCsvPath, 'get');
    if (params) {
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Statisztika csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodicStatisticsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodicStatisticsCsv(params?: {
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.periodicStatisticsCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
