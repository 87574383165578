/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DictionaryEntryStatus } from '../models/dictionary-entry-status';
import { ListTranslationsResult } from '../models/list-translations-result';
import { TranslationData } from '../models/translation-data';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dictionaryGet
   */
  static readonly DictionaryGetPath = '/api/Dictionary';

  /**
   * Fordítások listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryGet$Plain$Response(params?: {
    LanguageCode?: string;
    WordCodePattern?: string;
    HintPattern?: string;
    TranslationPattern?: string;
    Status?: DictionaryEntryStatus;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListTranslationsResult>> {

    const rb = new RequestBuilder(this.rootUrl, DictionaryService.DictionaryGetPath, 'get');
    if (params) {
      rb.query('LanguageCode', params.LanguageCode, {});
      rb.query('WordCodePattern', params.WordCodePattern, {});
      rb.query('HintPattern', params.HintPattern, {});
      rb.query('TranslationPattern', params.TranslationPattern, {});
      rb.query('Status', params.Status, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListTranslationsResult>;
      })
    );
  }

  /**
   * Fordítások listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryGet$Plain(params?: {
    LanguageCode?: string;
    WordCodePattern?: string;
    HintPattern?: string;
    TranslationPattern?: string;
    Status?: DictionaryEntryStatus;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListTranslationsResult> {

    return this.dictionaryGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListTranslationsResult>) => r.body as ListTranslationsResult)
    );
  }

  /**
   * Fordítások listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryGet$Response(params?: {
    LanguageCode?: string;
    WordCodePattern?: string;
    HintPattern?: string;
    TranslationPattern?: string;
    Status?: DictionaryEntryStatus;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListTranslationsResult>> {

    const rb = new RequestBuilder(this.rootUrl, DictionaryService.DictionaryGetPath, 'get');
    if (params) {
      rb.query('LanguageCode', params.LanguageCode, {});
      rb.query('WordCodePattern', params.WordCodePattern, {});
      rb.query('HintPattern', params.HintPattern, {});
      rb.query('TranslationPattern', params.TranslationPattern, {});
      rb.query('Status', params.Status, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListTranslationsResult>;
      })
    );
  }

  /**
   * Fordítások listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryGet(params?: {
    LanguageCode?: string;
    WordCodePattern?: string;
    HintPattern?: string;
    TranslationPattern?: string;
    Status?: DictionaryEntryStatus;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListTranslationsResult> {

    return this.dictionaryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListTranslationsResult>) => r.body as ListTranslationsResult)
    );
  }

  /**
   * Path part for operation post
   */
  static readonly PostPath = '/api/Dictionary';

  /**
   * Fordítás módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `post()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post$Response(params?: {
    body?: TranslationData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DictionaryService.PostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fordítás módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `post$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  post(params?: {
    body?: TranslationData
  },
  context?: HttpContext

): Observable<void> {

    return this.post$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
