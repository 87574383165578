import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FilterPipe } from '../../pipes/filter.pipe';
import { UINotificationService } from '../../../core/providers/ui-notification.service';

@Component({
    selector: 'list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent<TItem extends Object> implements OnInit {
    private _items?: TItem[];

    public get items() {
        return this._items;
    }

    @Input()
    public set items(value: TItem[] | undefined) {
        this._items = value;

        if (this.selectedItem && (!value || !value.includes(this.selectedItem))) {
            this.selectedItem = null;
        }
    }

    @Input()
    public canSelect = true;

    private _selectedItem: TItem | null = null;

    public get selectedItem() {
        return this._selectedItem;
    }

    @Input()
    public set selectedItem(value: TItem | null) {
        if (this._selectedItem === value) {
            return;
        }

        this._selectedItem = value;
        this.selectedItem$.emit(value);
    }

    @Output('selectedItemChange')
    public selectedItem$: EventEmitter<TItem | null> = new EventEmitter<TItem | null>();

    @ContentChild('listTemplate', { static: false })
    public listTemplate?: TemplateRef<any> | null;

    @ContentChild('emptyListTemplate', { static: false })
    public emptyListTemplate?: TemplateRef<any> | null;

    @ContentChild('listItemTemplate', { static: false })
    public listItemTemplate?: any;

    @ContentChild('defaultListTemplate', { static: false})
    public defaultListTemplate?: TemplateRef<any> | null;

    constructor(
        private titleService: Title,
        private notificationService: UINotificationService,
        private filterPipe: FilterPipe) {
    }

    public selectItem = (item: TItem | null) => {
        if (this.canSelect) {
            this.selectedItem = item;
        }
    }

    async ngOnInit() {
    }
}
