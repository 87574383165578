/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeleteSentNotificationCommand } from '../models/delete-sent-notification-command';
import { ListSentNotificationsQuery } from '../models/list-sent-notifications-query';
import { ListSentNotificationsResult } from '../models/list-sent-notifications-result';

@Injectable({
  providedIn: 'root',
})
export class SentNotificationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sentNotificationsListSentNotifications
   */
  static readonly SentNotificationsListSentNotificationsPath = '/api/SentNotifications/ListSentNotifications';

  /**
   * Feladott értesítések listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sentNotificationsListSentNotifications$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsListSentNotifications$Plain$Response(params?: {
    body?: ListSentNotificationsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListSentNotificationsResult>> {

    const rb = new RequestBuilder(this.rootUrl, SentNotificationsService.SentNotificationsListSentNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListSentNotificationsResult>;
      })
    );
  }

  /**
   * Feladott értesítések listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sentNotificationsListSentNotifications$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsListSentNotifications$Plain(params?: {
    body?: ListSentNotificationsQuery
  },
  context?: HttpContext

): Observable<ListSentNotificationsResult> {

    return this.sentNotificationsListSentNotifications$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListSentNotificationsResult>) => r.body as ListSentNotificationsResult)
    );
  }

  /**
   * Feladott értesítések listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sentNotificationsListSentNotifications()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsListSentNotifications$Response(params?: {
    body?: ListSentNotificationsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListSentNotificationsResult>> {

    const rb = new RequestBuilder(this.rootUrl, SentNotificationsService.SentNotificationsListSentNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListSentNotificationsResult>;
      })
    );
  }

  /**
   * Feladott értesítések listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sentNotificationsListSentNotifications$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsListSentNotifications(params?: {
    body?: ListSentNotificationsQuery
  },
  context?: HttpContext

): Observable<ListSentNotificationsResult> {

    return this.sentNotificationsListSentNotifications$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListSentNotificationsResult>) => r.body as ListSentNotificationsResult)
    );
  }

  /**
   * Path part for operation sentNotificationsDelete
   */
  static readonly SentNotificationsDeletePath = '/api/SentNotifications/Delete';

  /**
   * Feladott értesítés törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sentNotificationsDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsDelete$Response(params?: {
    body?: DeleteSentNotificationCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SentNotificationsService.SentNotificationsDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Feladott értesítés törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sentNotificationsDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sentNotificationsDelete(params?: {
    body?: DeleteSentNotificationCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.sentNotificationsDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
