import { Injectable, EventEmitter } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

@Injectable()
export class BackgroundEventUserService {
    eventReceived = new EventEmitter<[number, number]>();
    connectionEstablished = new EventEmitter<Boolean>();

    private connectionIsEstablished = false;
    private _hubConnection: HubConnection | undefined;

    constructor() {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }

    private createConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl('/BackgroundEventUsersHub')
            .build();
    }

    private startConnection(): void {
        if (this._hubConnection !== undefined) {
            this._hubConnection
                .start()
                .then(() => {
                    this.connectionIsEstablished = true;
                    this.connectionEstablished.emit(true);
                })
                .catch(err => {
                    console.log('Error while establishing connection, retrying...');
                    setTimeout(() => { this.startConnection(); }, 5000);
                });
        }
    }

    private registerOnServerEvents(): void {
        if (this._hubConnection !== undefined) {
            this._hubConnection.on('EmitEventUser', (eventId: number, userId: number) => {
                this.eventReceived.emit([eventId, userId]);
            });
        }
    }
}