/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateUserCardFieldCommand } from '../models/create-or-update-user-card-field-command';
import { RemoveUserCardFieldCommand } from '../models/remove-user-card-field-command';
import { UserCardFieldData } from '../models/user-card-field-data';

@Injectable({
  providedIn: 'root',
})
export class UserCardFieldsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userCardFieldsGet
   */
  static readonly UserCardFieldsGetPath = '/api/UserCardFields/Get';

  /**
   * Felhasználói azonosítók lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardFieldsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardFieldsGet$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserCardFieldData>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardFieldsService.UserCardFieldsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserCardFieldData>>;
      })
    );
  }

  /**
   * Felhasználói azonosítók lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardFieldsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardFieldsGet$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserCardFieldData>> {

    return this.userCardFieldsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserCardFieldData>>) => r.body as Array<UserCardFieldData>)
    );
  }

  /**
   * Felhasználói azonosítók lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardFieldsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardFieldsGet$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserCardFieldData>>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardFieldsService.UserCardFieldsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserCardFieldData>>;
      })
    );
  }

  /**
   * Felhasználói azonosítók lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardFieldsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCardFieldsGet(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserCardFieldData>> {

    return this.userCardFieldsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserCardFieldData>>) => r.body as Array<UserCardFieldData>)
    );
  }

  /**
   * Path part for operation userCardFieldsPost
   */
  static readonly UserCardFieldsPostPath = '/api/UserCardFields/Post/Post';

  /**
   * Felhasználói azonosító létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardFieldsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardFieldsPost$Response(params?: {
    body?: CreateOrUpdateUserCardFieldCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardFieldsService.UserCardFieldsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználói azonosító létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardFieldsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardFieldsPost(params?: {
    body?: CreateOrUpdateUserCardFieldCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userCardFieldsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userCardFieldsRemove
   */
  static readonly UserCardFieldsRemovePath = '/api/UserCardFields/Remove/Remove';

  /**
   * Felhasználói azonosító törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCardFieldsRemove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardFieldsRemove$Response(params?: {
    body?: RemoveUserCardFieldCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserCardFieldsService.UserCardFieldsRemovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználói azonosító törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCardFieldsRemove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCardFieldsRemove(params?: {
    body?: RemoveUserCardFieldCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userCardFieldsRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
