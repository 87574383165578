/* tslint:disable */
export enum UserState {
  Preregistered = 1,
  LinkSent = 2,
  Registered = 3,
  Applicant = 4,
  Admissioned = 5,
  Active = 6,
  Inactive = 7,
  Deleted = 8
}

