import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UINotificationService } from './ui-notification.service';
import { SessionProvider } from './session.provider';
import { ShortcutNavigationError } from '../../shared/classes/shortcut-navigation-error';
import { Router } from '@angular/router';
import { ApiErrorData } from '../../../api/models/api-error-data';
import { Http400ErrorHandlerParameter } from '../../shared/classes/http400errorhandler-parameter';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private translator: TranslateService,
    private monitoringService: MonitoringService) { 
  }

  private readonly SERVICEERRORCODE_PREFIX = 'UI.Message.ServiceErrorCode.';

  handleError(error: any) {
    const unwrappedError = error.promise !== undefined ? error.rejection : error;

    if (unwrappedError instanceof HttpErrorResponse) {
      console.error(`Unexpected API error with status code ${unwrappedError.status}`, unwrappedError.error);
    } else if (unwrappedError instanceof ShortcutNavigationError) {
      const ngZone = this.injector.get<NgZone>(NgZone);
      const router = this.injector.get<Router>(Router);
      ngZone.run(() => unwrappedError.navigationAction(router));
      return;
    } else {
      this.monitoringService.logException(error);
      console.error('An error occurred in the application.', error);
    }

    if (!environment.production) {
      // production környezetben nem használunk debuggert
      // tslint:disable-next-line
      debugger;
    }
  }

  /**
   * Általános hibakezelő logika elkapott hibák kezeléséhez.
   * Ha adunk át hibaüzenetet a függvénynek, akkor az egy modális dialógusablak formájában megjelenítésre kerül,
   * egyébként a default általános hibakezelési logika fog lefutni (a 403-as hiba általános kezelésével kiegészítve).
   */
  async handleExpectedError(error: any, message?: string, title?: string, info?: string, isConfirm?: boolean, onClose?: (result:string) => void, type?: 'error' | 'warning' | 'info' | 'none', disableOnClose?: boolean, backdropClass?: string, isMobile?: boolean) {
    const translator = this.injector.get<TranslateService>(TranslateService);
    const notificationService = this.injector.get<UINotificationService>(UINotificationService);

    if (error && !message) {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        const session = this.injector.get<SessionProvider>(SessionProvider);

        await notificationService.showModalMessage({
          type: type ?? 'error',
          title: translator.instant('UI.Label.PermissionDenied|Jogosulatlan hozzáférés'),
          message: translator.instant('UI.Message.PermissionDenied|Ön jelenleg nem rendelkezik a művelet végrehajtásához ' +
            'szükséges jogosultsággal, mivel feltehetően időközben visszavonták azt. Az üzenet bezárása után átirányítjuk ' +
            'a kezdőoldalra.'),
            backdropClass: backdropClass,
            isMobile: isMobile
        });

        throw new ShortcutNavigationError(router => session.navigateToHomePage());
      }

      this.handleError(error);
    }

    return await notificationService.showModalMessage({
      type: type ?? 'error',
      title: title || translator.instant('UI.Label.Error|Hiba'),
      message: message || translator.instant('UI.Message.UnexpectedError|Ismeretlen hiba történt. Próbálja újra később, ' +
        'vagy értesítse az üzemeltetőt, ha a hiba továbbra is fennáll!'),
      info: info,
      buttons: isConfirm ? ['cancel', 'ok'] : undefined,
      buttonText: isConfirm ? [translator.instant('UI.Label.Common.Cancel|Mégse'), translator.instant('UI.Label.Common.Save|Mentés')] : undefined,
      onClose: onClose,
      disableClose: disableOnClose,
      backdropClass: backdropClass,
      isMobile : isMobile
    });
  }

  async handleHTTP400Errors(p: Http400ErrorHandlerParameter): Promise<[boolean, boolean | null | undefined]> {
    let $error: unknown | undefined;
    let info: string | undefined = undefined;
    let changeAllowed: boolean | null | undefined;

    if (p.errorMessage === undefined && p.error instanceof HttpErrorResponse && p.error.status === 400 && p.error.error) {
      let apiError = p.error.error as ApiErrorData;
      if (apiError.errorCode === undefined) {
        try {
          apiError = JSON.parse(p.error.error);
        } catch (jsonparseError) { }
      }
      p.errorMessage = this.translator.instant(this.SERVICEERRORCODE_PREFIX + apiError.errorCode);
      changeAllowed = apiError.changeAllowed;

      if (apiError.errorDetails !== undefined) {
        const regex = /{\d+}/g;
        const hasValues = p.errorMessage?.match(regex);
        if (p.inlineInfo === true && hasValues !== null && hasValues !== undefined && hasValues.length > 0) {
          apiError.errorDetails?.forEach((detail, index) => {
            p.errorMessage = p.errorMessage?.replace('{' + index + '}', detail);
          });
        } else {
          info = apiError.errorDetails?.join(', ');
        }
      }
    }

    $error = p.error;
    if ($error) {
      await this.handleExpectedError($error, p.errorMessage, p.title, info, p.isConfirm, p.onClose, p.type, p.disableOnClose, p.backdropClass, p.isMobile);
    }

    return [true, changeAllowed];
  }
}
