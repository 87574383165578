/* tslint:disable */
export enum ReportState {
  /// <summary>
  /// Azok felhasználók kapnak “Lejelenthető” feliratot, akik olyan eseményen csapattagok, vagy tartalékosok amelynek az Esemény kártyáján megnyomásra került a Lejelentés gomb.
  /// </summary>
  Reportable = 1,

  /// <summary>
  /// Azon felhasználók kapnak “Lejelenthető (SOS)” feliratot, akik az esemény kártya Lejelentés gomb megnyomásakor nem voltak csapattagok, vagy tartalékosok, de mégis
  /// megjelentek a munkavégzés helyszínén, és szükségünk is van a munkájukra. Így maradnak dolgozni, és a Esemény/Esemény beállításai/ ikonnál pipa, vagy K (mint késő)
  /// jelölést kapták. 
  /// </summary>
  ReportableSOS = 2,

  /// <summary>
  /// Azt jelenti, hogy az adott eseményre jelentkezett felhasználót lejelentett a Munkaáltatója.
  /// </summary>
  Reported = 3,

  /// <summary>
  /// A már lejelentett, de a munkavégzés helyszínén meg nem jelent (Esemény beállításai -nál jelezve) munkavállaló NAV-os lejelentését törölni kell.
  /// </summary>
  Deletable = 4,

  /// <summary>
  /// Az adott felhasználó NAV-os lejelentése törölve lett.
  /// </summary>
  Deleted = 5,
}

