/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdvertisementData } from '../models/advertisement-data';
import { CreateOrUpdateAdvertisementCommand } from '../models/create-or-update-advertisement-command';
import { RemoveAdvertisementCommand } from '../models/remove-advertisement-command';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation advertisementsGet
   */
  static readonly AdvertisementsGetPath = '/api/Advertisements/Get';

  /**
   * Hirdetések lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsGet$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AdvertisementData>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdvertisementData>>;
      })
    );
  }

  /**
   * Hirdetések lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsGet$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<AdvertisementData>> {

    return this.advertisementsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AdvertisementData>>) => r.body as Array<AdvertisementData>)
    );
  }

  /**
   * Hirdetések lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsGet$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AdvertisementData>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsGetPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdvertisementData>>;
      })
    );
  }

  /**
   * Hirdetések lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsGet(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<AdvertisementData>> {

    return this.advertisementsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AdvertisementData>>) => r.body as Array<AdvertisementData>)
    );
  }

  /**
   * Path part for operation advertisementsPost
   */
  static readonly AdvertisementsPostPath = '/api/Advertisements/Post/Post';

  /**
   * Hirdetések létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  advertisementsPost$Response(params?: {
    body?: CreateOrUpdateAdvertisementCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Hirdetések létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  advertisementsPost(params?: {
    body?: CreateOrUpdateAdvertisementCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.advertisementsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation advertisementsRemove
   */
  static readonly AdvertisementsRemovePath = '/api/Advertisements/Remove/Remove';

  /**
   * Hirdetés törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsRemove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  advertisementsRemove$Response(params?: {
    body?: RemoveAdvertisementCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsRemovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Hirdetés törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsRemove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  advertisementsRemove(params?: {
    body?: RemoveAdvertisementCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.advertisementsRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation advertisementsIsUnique
   */
  static readonly AdvertisementsIsUniquePath = '/api/Advertisements/IsUnique';

  /**
   * Hirdetéskód egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsIsUnique$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsIsUnique$Plain$Response(params?: {
    Code?: string;
    Id?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsIsUniquePath, 'get');
    if (params) {
      rb.query('Code', params.Code, {});
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Hirdetéskód egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsIsUnique$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsIsUnique$Plain(params?: {
    Code?: string;
    Id?: number;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.advertisementsIsUnique$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Hirdetéskód egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advertisementsIsUnique()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsIsUnique$Response(params?: {
    Code?: string;
    Id?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertisementsService.AdvertisementsIsUniquePath, 'get');
    if (params) {
      rb.query('Code', params.Code, {});
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Hirdetéskód egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `advertisementsIsUnique$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  advertisementsIsUnique(params?: {
    Code?: string;
    Id?: number;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.advertisementsIsUnique$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
