/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AcceptManualCommand } from '../models/accept-manual-command';
import { CreateOrUpdateManualCommand } from '../models/create-or-update-manual-command';
import { ListManualsQuery } from '../models/list-manuals-query';
import { ListManualsResult } from '../models/list-manuals-result';
import { ManualData } from '../models/manual-data';
import { ManualType } from '../models/manual-type';

@Injectable({
  providedIn: 'root',
})
export class ManualService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation manualListManuals
   */
  static readonly ManualListManualsPath = '/api/Manual/ListManuals';

  /**
   * Szabályzatok listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualListManuals$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualListManuals$Plain$Response(params?: {
    body?: ListManualsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListManualsResult>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualListManualsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListManualsResult>;
      })
    );
  }

  /**
   * Szabályzatok listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualListManuals$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualListManuals$Plain(params?: {
    body?: ListManualsQuery
  },
  context?: HttpContext

): Observable<ListManualsResult> {

    return this.manualListManuals$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListManualsResult>) => r.body as ListManualsResult)
    );
  }

  /**
   * Szabályzatok listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualListManuals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualListManuals$Response(params?: {
    body?: ListManualsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListManualsResult>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualListManualsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListManualsResult>;
      })
    );
  }

  /**
   * Szabályzatok listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualListManuals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualListManuals(params?: {
    body?: ListManualsQuery
  },
  context?: HttpContext

): Observable<ListManualsResult> {

    return this.manualListManuals$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListManualsResult>) => r.body as ListManualsResult)
    );
  }

  /**
   * Path part for operation manualPost
   */
  static readonly ManualPostPath = '/api/Manual/Post';

  /**
   * Szabályzat létrehozása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualPost$Plain$Response(params?: {
    body?: CreateOrUpdateManualCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Szabályzat létrehozása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualPost$Plain(params?: {
    body?: CreateOrUpdateManualCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.manualPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Szabályzat létrehozása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualPost$Response(params?: {
    body?: CreateOrUpdateManualCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Szabályzat létrehozása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualPost(params?: {
    body?: CreateOrUpdateManualCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.manualPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation manualAcceptManual
   */
  static readonly ManualAcceptManualPath = '/api/Manual/AcceptManual';

  /**
   * Szabályzat elfogadása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualAcceptManual$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualAcceptManual$Plain$Response(params?: {
    body?: AcceptManualCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualAcceptManualPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Szabályzat elfogadása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualAcceptManual$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualAcceptManual$Plain(params?: {
    body?: AcceptManualCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.manualAcceptManual$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Szabályzat elfogadása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualAcceptManual()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualAcceptManual$Response(params?: {
    body?: AcceptManualCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualAcceptManualPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Szabályzat elfogadása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualAcceptManual$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  manualAcceptManual(params?: {
    body?: AcceptManualCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.manualAcceptManual$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation manualGetCurrentManual
   */
  static readonly ManualGetCurrentManualPath = '/api/Manual';

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualGetCurrentManual$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  manualGetCurrentManual$Plain$Response(params?: {
    ManualType?: ManualType;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ManualData>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualGetCurrentManualPath, 'get');
    if (params) {
      rb.query('ManualType', params.ManualType, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualData>;
      })
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualGetCurrentManual$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manualGetCurrentManual$Plain(params?: {
    ManualType?: ManualType;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<ManualData> {

    return this.manualGetCurrentManual$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ManualData>) => r.body as ManualData)
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualGetCurrentManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  manualGetCurrentManual$Response(params?: {
    ManualType?: ManualType;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ManualData>> {

    const rb = new RequestBuilder(this.rootUrl, ManualService.ManualGetCurrentManualPath, 'get');
    if (params) {
      rb.query('ManualType', params.ManualType, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualData>;
      })
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manualGetCurrentManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manualGetCurrentManual(params?: {
    ManualType?: ManualType;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<ManualData> {

    return this.manualGetCurrentManual$Response(params,context).pipe(
      map((r: StrictHttpResponse<ManualData>) => r.body as ManualData)
    );
  }

}
